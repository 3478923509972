import { Row, Col } from 'antd';
import '../../App.css';
import './Projects.css';
import '../../pages/Home/Home.css';
import project1 from '../../img/Home/project1.jpg';
import project2 from '../../img/Home/project2.jpg';
import ReactGA from 'react-ga';
import { useEffect } from 'react';

const Projects = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])
    return (<div className='darkblue-color'>
        <div className="page-banner project-banner">
            <div className="t2 bold1-txt center mb">Projects</div>
            <div className="t5 center bold4-txt">Here’s a selection of our <span className="yellow-color">featured website design</span>  , development and ecommerce projects.</div>
        </div>
        <div className='project-list'>
            <Row gutter={[64, 16]}>
                <Col md={24} lg={12}>
                    <a href='https://www.flometal.com/'>
                        <div><img src={project1} width='100%' height='350px' alt='33'/></div>
                        <div className='t3 bold1-txt project-title white-color'>Flo Pipes & Fittings</div>
                    </a>
                </Col>
                <Col md={24} lg={12}>
                    <a href='https://isngs.com/digital-marketing/index.html'>
                        <div><img src={project2} width='100%' height='350px' className='p2' alt='34'/></div>
                        <div className='t3 bold1-txt project-title white-color'>Landing Page For Contractors</div>
                    </a>
                </Col>
            </Row>
        </div>
        <div className='project-contact-us'>
            <div className='t3 bold1-txt center mb'>We generate revenue for businesses</div>
            <div className='t6 center mb2 bold1-txt'>Digital Buzzers is a digital solutions agency that creates stunning websites for small and medium businesses. We use our own research and the latest data to help you get an incredible return on investment.</div>
            <div className='center'><button htmlType="submit" className='submit-btn blue-white-btn t5 bold1-txt'><a href='/contact' className='white-color'>Talk to our experts</a></button></div>
        </div>
    </div>)
}
export default Projects;