
import e1 from '../../img/Services/emailsms/Its-all-about-marketing.png';
import e2 from '../../img/Services/emailsms/High-return-on-investment.png';
import FreeConsultation from "../AboutUs/FreeConsultation";
import { Row, Col } from 'antd';
import AboutUsSlider from "../AboutUs/AboutUsSlider";
import mobilefriendly from '../../img/Services/WebDesing/mobile-friendly.png';
import notemplates from '../../img/Services/WebDesing/No-templates.png';
import gdpr from '../../img/Services/WebDesing/Gdpr-Compliant.png';
import editablecontent from '../../img/Services/WebDesing/Editable-content.png';
import rocket from '../../img/Services/WebDesing/Optimised-for-speed.png';
import BasicCard from '../../Component/BasicCard/BasicCard';
import customFoucs from '../../img/Services/WebDesing/Customer-focused.png'
import ReactGA from 'react-ga';
import { useEffect } from 'react';
import { Helmet } from "react-helmet";

const EmailSMSMarketing=()=>{
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])
    return(<>
    <Helmet>
            <meta charSet="utf-8" />
            <title>Best E-Mail & SMS Marketing Company | Digital Buzzers</title>
            <meta name="Digital Buzzers is a leading bulk e-mail & sms service provider company. We are listed as top Digital Marketing Agency based in UK & US. Contact us today!"
                content="
                email marketing in digital marketing,
                best email marketing providers,
                ecommerce email marketing agency,
                sms marketing company,
                best sms marketing
                
                "/>
        </Helmet>
    <div className="page-banner contact-banner">
            <div className="t2 bold1-txt center mb">Email & SMS Marketing</div>
            <div className="t5 center bold1-txt">Turn one-time shoppers into loyal customers with our advanced personalization approach to <span className="yellow-color">email and SMS</span>.</div>
        </div>
        <Row>
            <Col xs={24} md={24} lg={12}>
                <img src={e1} height='100%' width='100%' alt='38'/>
            </Col>
            <Col xs={24} md={24} lg={12} className='data-creativity-content'>
                <div className='t4 bold1-txt mb2'>It’s all about results</div>
                <div className='t6 mb bold4-txt'>At Digital Buzzers, we understand a direct line of communication with your customers and prospects can be your business’s most valuable asset.Our expertly-curated email & SMS strategy starts here.</div>
                <div className='t6 mb bold4-txt'>We will increase your reach using email, SMS, Messenger and more. Consistency is key. We send the right message at the right time to the right audience.</div>
                <div className='t6 mb bold4-txt'>We carefully track and report the performance of your email and SMS campaigns, monitoring open rates, click-through rates, conversions, and unsubscribes so that we can refine the effectiveness of your campaigns over time.</div>
                <Row align='middle' gutter={[8, 16]} className='mt2'>
                    <Col xs={24} md={7} lg={12}>
                        <button htmlType="submit" className='submit-btn services-web-design-btn t5 bold1-txt'><a href='#free-consulation' className='darkblue-color services-web-design-btn-txt'>Increase My Revenue</a></button>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row className="box-shadow">
            <Col xs={24} md={0} lg={0}>
                <img src={e2} height='100%' width='100%' alt='37'/>
            </Col>
            <Col xs={24} md={24} lg={12} className='data-creativity-content'>
                <div className='t4 bold1-txt mb2'>Our Email & SMS Approach</div>
                <div className='t6 mb bold4-txt'>Our team will work with you to set your retention goals and create your Ideal Customer Profile (ICP) that will help us build personalized content and track the success of your campaigns.</div>
                <div className='t6 mb bold4-txt'>Using historical and psychographic data, we’ll segment your audience based on their preferences and experience with your brand. From there, we’ll put them in marketable list that will be the foundation of your campaign.</div>
                <div className='t6 mb bold4-txt'>Our team will study your customer journey from end-to-end, uncovering email and SMS opportunities along the way. This will determine the frequency of your campaigns, opportunities for automation, and the content that your customers will love.</div>
                <Row align='middle' gutter={[8, 16]} className='mt2'>
                    <Col xs={24} md={9} lg={12}>
                        <button htmlType="submit" className='submit-btn services-web-design-btn t5 bold1-txt'><a href='#free-consulation' className='darkblue-color services-web-design-btn-txt'>Book a Consulation</a></button>
                    </Col>
                    <Col xs={24} md={15} lg={12} >
                    <div className='t6 bold4-txt'> or call us on <a href="tel:9062758799" className="yellow-color" target={'_blank'}> (+91) 982 079 6941</a></div>
                    </Col>
                </Row>
            </Col>
            <Col xs={0} md={24} lg={12}>
                <img src={e2} height='100%' width='100%' alt='36'/>
            </Col>
        </Row>
        
        <div className='we-are-different'>
                <div className='t4 bold1-txt center mb'>The Email & SMS tools you need to grow and retain.</div>
                <div className='t6 bold1-txt center mb2'>Boost ROI and average order value with campaigns, signup forms, and flows that keep your audience engaged.</div>
                <br />
                <Row gutter={[32, 32]} >
                    <Col xs={24} md={12} lg={8}>
                        <BasicCard
                            img={mobilefriendly}
                            description='Succeeding with email is all about relevancy. We’ll help you segment your audience based on behavior analytics, psychographics, and historical data to build email workflows that save you time, drive more revenue, and increase customer retention.'
                            title='Email Marketing'
                        />
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <BasicCard
                            img={customFoucs}
                            description='Texting is quickly becoming consumers go-to way to engage with their favorite brands. Build relationships with your customers with welcome offers, product announcements, and more.'
                            title='SMS Marketing'
                        />
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <BasicCard
                            img={notemplates}
                            description='Engage with your customers like never before with behavior-based automation that sends the right message at the right time, with product recommendations based on their purchase history, products they’ve viewed, and more.'
                            title='Advanced Personalization'
                        />
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <BasicCard
                            img={gdpr}
                            description='Stop sending boring emails. Highlight your brand and your product with creative, data-driven email designs that are designed to inspire your customers to take action.'
                            title='Custom Designs'
                        />
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <BasicCard
                            img={editablecontent}
                            description='Improve the post-purchase experience for your customers and increase customer retention by tracking the performance of your post-purchase emails.'
                            title='Transaction Automation'
                        />
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <BasicCard
                            img={rocket}
                            description='Our team of expert retention marketers are dedicated to finding what works best for your brand. We’ll run A/B tests, experiment with different send times.'
                            title='Ongoing Optimization'
                        />
                    </Col>
                </Row>
            </div>
            <AboutUsSlider
            sliderTxt={[
                'I have been extremely impressed by the customer service, attention to detail and all-round performance of this fantastically well run company. I will be highly recommending to anyone and everyone!',
                'Digital Buzzers built us a beautiful ecommerce website with a wealth of custom features and layouts. The backend is super simple to use, and their marketing, advice and SEO offering have meant that we have hit the ground running and are already bringing in sales!',
                'They took us through the design process from concept to delivery for our new school website. We are extremely pleased with Digital Buzzers service.'
            ]} />
        <FreeConsultation />
        
        </>)
}
export default EmailSMSMarketing;