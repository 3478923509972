import { useState } from "react";
import { Form, Input, Row, Col, Button, Select, Spin,message } from "antd";
import ReactGA from 'react-ga';
import axios from "axios";
import '../../App.css';
import './Contact.css';
const { TextArea } = Input;
const { Option } = Select;

const ContactForm = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const submitContactUsForm = (val) => {
        setLoading(true);
        var formData = new FormData();
        Object.keys(val).map(item => {
            formData.append(item, val[item]);
        })
        axios
            .post("https://script.google.com/macros/s/AKfycbyB4lx-g1L2xYE7IfKzjQ6ha-r5HiwaMzVjAS4F9Ee8Q9Yb2wgLNR0-c1DLZm9tF_wf/exec", formData)
            .then(function (response) {
                setLoading(false);
                messageApi.open({
                    type: 'success',
                    content: 'Thank you for considering Digital Buzzers for your digital growth. We will contact you within one business day.',
                });
                ReactGA.event({
                    category: val.name,
                    action: val.number,
                    label: val.message,
                    value: val.email
                });
            })
            .catch(function (error) {
                setLoading(false);messageApi.open({
                    type: 'error',
                    content: 'Please fill out these forms again....',
                });
            });
        form.resetFields();
    }
    return (<div className='contact_form'>
        <Spin spinning={loading}>
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                onFinish={submitContactUsForm}
            >
                <Row gutter={[16, 16]} >
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your full name!',
                                },
                            ]}
                        >
                            <Input placeholder="Full Name" />
                        </Form.Item>
                        <Form.Item
                            name="Number"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter number!',
                                },
                                {
                                    required: true,
                                    pattern: /^[\d]{10}$/,
                                    message: "Value should contain just number",
                                },
    
                            ]}
                        >
                            <Input placeholder="Phone Number" />
                        </Form.Item>
                        <Form.Item
                            name="Website"
                        >
                            <Input placeholder="Website if any" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="Email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                },
                                {
                                    required: true,
                                    message: 'Please enter your E-mail!',
                                },
                            ]}
                        >
                            <Input placeholder="Email Address" />
                        </Form.Item>
                        <Form.Item
                            name="Message"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter message!',
                                },
                            ]}
                        >
                            <TextArea placeholder="How We Can Help You?" rows={3} />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    label="What services are you interested in?"
                    name="Services"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter message!',
                        },
                    ]}
                >
                    <Select mode="multiple" placeholder='Select services' >
                        <Option value="ppc">Pay Per Click Marketing</Option>
                        <Option value="seo"> Search Engine Optimization</Option>
                        <Option value="web_design">Web Design and Development</Option>
                        <Option value="email_sms">Email & SMS Marketing</Option>
                        <Option value="smm">Social Media Management</Option>
                        <Option value="other">Other</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{ textAlign: 'center' }}
                >
                    <Button type="primary" htmlType="submit" size="large">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    </div>)
}
export default ContactForm;