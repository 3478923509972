import { Row, Col } from "antd";
import { IdcardOutlined, PhoneOutlined, MailOutlined } from '@ant-design/icons'
import contactImg from '../../img/Contact/contact-page-bg.png';
import ContactForm from "./ContactForm";
import '../../App.css';
import './Contact.css';
import ReactGA from 'react-ga';
import { useEffect } from 'react';

const Contact = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])
    return (<div className="darkblue-color contact-page">
        <div className="page-banner contact-banner">
            <div className="t1 bold1-txt center mb2">Contact</div>
            <div className="t5 center bold4-txt">We're a fully <span className="yellow-color">remote-working company</span> with staff throughout United States and India. We would love to know how we can help...</div>
        </div>
        <Row>
            <Col lg={12}>
                <img src={contactImg} alt='30' width='100%' height='100%' />
            </Col>
            <Col lg={12}>
                <ContactForm />
            </Col>
        </Row>
        <Row justify='center'>
            <Col xs={24} md={24} lg={10}>
                <div className="contact-details">
                    <div className="t2 bold1-txt mb2 center">Get In Touch</div>
                    <div>
                        <div className="t6 bold1-txt mb"><IdcardOutlined className="t5 mr" />Address</div>
                        <div className="t6 mb2">67B, Gopal Mansion, Bandra West, Mumbai 400050.</div>
                        <hr className="mt mb" />
                        <div className="t6 bold1-txt mb" ><PhoneOutlined className="t5 mr" />Phone Number</div>
                        <div className="t6 mb2">(+91) 982 079 6941</div>
                        <hr className="mt mb" />
                        <div className="t6 bold1-txt mb" ><MailOutlined className="t5 mr" />Email Address</div>
                        <div className="t6 mb2">Hello@digitalbuzzers.com</div>
                        <hr className="mt mb" />
                    </div>
                </div>
            </Col>
            <Col xs={24} md={24} lg={14}>
                <iframe src=
                    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d85593.588849016!2d-122.1856771!3d47.90156295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54900718fb452b75%3A0x86de82a64124b2fa!2sEverett%2C%20WA%2098208%2C%20USA!5e0!3m2!1sen!2sin!4v1683139872889!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    frameborder="0"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                    >
                </iframe>
            </Col>
        </Row>
    </div>)
}
export default Contact;