
import './style.css'
import { Row, Col } from 'antd';
const NonVisibleCode = () => {
    return (<div className="nonvisiblecode">
        <Row>
            <Col span={12}>
                <h3>Social media platforms are evolving into shopping destinations. With features like shoppable posts and in-app purchases, businesses can turn social media followers into customers seamlessly.</h3>
                <p>
                    Set up shoppable posts on platforms like Instagram and Pinterest to allow users to buy products directly from your posts.
                    Use social media advertising to target specific demographics and drive sales.
                    Leverage user-generated content to build trust and authenticity in your social commerce efforts.
                </p>
            </Col>
            <Col span={12}>
                <h3>SEO and Voice Search</h3>
                <p>
                    Search engine optimization (SEO) continues to be a vital component of digital marketing. However, the rise of voice search presents new challenges and opportunities:
                </p>
            </Col>
            <Col span={12}>
                <h3>Email Marketing</h3>
                <p>
                Email marketing remains one of the most effective ways to engage with your existing customer base and nurture leads. It involves sending personalized emails, newsletters, and promotional messages to subscribers. Email automation tools can help you segment your audience and deliver tailored content, improving open rates and conversions
                </p>
            </Col>
            <Col span={12}>
                <h3>Video Marketing</h3>
                <p>
                Video content is highly engaging. Consider creating video content that addresses local topics or showcases your products and services in a way that resonates with UK consumers.
                </p>
            </Col>
        </Row>
    </div>)
}
export default NonVisibleCode;