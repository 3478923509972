import { useRef } from 'react';
import { Carousel, Row, Col } from "antd";
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import webdesign from '../../img/Home/webDesign.png';
import ppc from '../../img/Home/ppc.png';
import emailsms from '../../img/Home/emailsms.png';
import seo from '../../img/Home/seo.png';
import techServices from '../../img/Home/Tech -services.png';
import contentCreation from '../../img/Home/Content creaton.png';
import socialmedia from '../../img/Home/socialmedia.png'
import BasicCard from '../../Component/BasicCard/BasicCard';
import '../../App.css';
import './Home.css'

const AllServices = [
    {
        icon: webdesign,
        title: 'Web Design',
        description: 'Our digital team will build the bespoke brochure or e-commerce website your business needs to thrive. Focusing on conversion rate optimisation...',
        link: '/services/web-design'
    },
    {
        icon: ppc,
        title: 'PPC Advertising',
        description: 'Stop struggling with lead generation and marketing ideas. Digital Buzzers will deliver exclusive, targeted and high-intent leads...',
        link: '/services/ppc-advertising'
    },
    {
        icon: seo,
        title: 'SEO',
        description: 'Get more qualified traffic on the search terms that matter most to your business. We analyze every variable that affects SERPs...',
        link: '/services/seo'
    },
    {
        icon: socialmedia,
        title: 'Social Media ',
        description: 'Every brand is different, so your social media management strategy should be as well. We prioritize a high level of attention to detail...',
        link: '/services/social-media'
    },
    {
        icon: emailsms,
        title: 'Email & SMS Marketing',
        description: 'A direct line of communication with your customers and prospects can be your business’s most valuable asset. Our expertly-curated email...',
        link: '/services/email-sms-marketing'
    },
    {
        icon: contentCreation,
        title: 'Content Creation ',
        description: 'Every brand is different, so your social media management strategy should be as well. We prioritize a high level of attention to detail...',
        link: '/services/content-creation'
    },
    {
        icon: techServices,
        title: 'Technical Services',
        description: 'A direct line of communication with your customers and prospects can be your business’s most valuable asset. Our expertly-curated email...',
        link: '/services/tech-services'
    }
]

const MD_AllServices = [
    [{
        icon: webdesign,
        title: 'Web Design',
        description: 'Our digital team will build the bespoke brochure or e-commerce website your business needs to thrive. Focusing on conversion rate optimisation...',
        link: '/services/web-design'
    },
    {
        icon: ppc,
        title: 'PPC Advertising',
        description: 'Stop struggling with lead generation and marketing ideas. Digital Buzzers will deliver exclusive, targeted and high-intent leads...',
        link: '/services/ppc-advertising'
    }],
    [{
        icon: ppc,
        title: 'PPC Advertising',
        description: 'Stop struggling with lead generation and marketing ideas. Digital Buzzers will deliver exclusive, targeted and high-intent leads...',
        link: '/services/ppc-advertising'
    },
    {
        icon: seo,
        title: 'SEO',
        description: 'Get more qualified traffic on the search terms that matter most to your business. We analyze every variable that affects SERPs...',
        link: '/services/seo'
    }
    ],
    [{
        icon: seo,
        title: 'SEO',
        description: 'Get more qualified traffic on the search terms that matter most to your business. We analyze every variable that affects SERPs...',
        link: '/services/seo'
    },
    {
        icon: socialmedia,
        title: 'Social Media ',
        description: 'Every brand is different, so your social media management strategy should be as well. We prioritize a high level of attention to detail...',
        link: '/services/social-media'
    },
    ],
    [
        {
            icon: socialmedia,
            title: 'Social Media ',
            description: 'Every brand is different, so your social media management strategy should be as well. We prioritize a high level of attention to detail...',
            link: '/services/social-media'
        },
        {
            icon: emailsms,
            title: 'Email & SMS Marketing',
            description: 'A direct line of communication with your customers and prospects can be your business’s most valuable asset. Our expertly-curated email...',
            link: '/services/email-sms-marketing'
        }
    ],
    [
        {
            icon: emailsms,
            title: 'Email & SMS Marketing',
            description: 'A direct line of communication with your customers and prospects can be your business’s most valuable asset. Our expertly-curated email...',
            link: '/services/email-sms-marketing'
        },
        {
            icon: contentCreation,
            title: 'Content Creation ',
            description: 'Every brand is different, so your social media management strategy should be as well. We prioritize a high level of attention to detail...',
            link: '/services/content-creation'
        }
    ],
    [
        {
            icon: contentCreation,
            title: 'Content Creation ',
            description: 'Every brand is different, so your social media management strategy should be as well. We prioritize a high level of attention to detail...',
            link: '/services/content-creation'
        },
        {
            icon: techServices,
            title: 'Technical Services',
            description: 'A direct line of communication with your customers and prospects can be your business’s most valuable asset. Our expertly-curated email...',
            link: '/services/tech-services'
        }
    ]
]

const LG_AllServices = [
    [{
        icon: webdesign,
        title: 'Web Design',
        description: 'Our digital team will build the bespoke brochure or e-commerce website your business needs to thrive. Focusing on conversion rate optimisation...',
        link: '/services/web-design'
    },
    {
        icon: ppc,
        title: 'PPC Advertising',
        description: 'Stop struggling with lead generation and marketing ideas. Digital Buzzers will deliver exclusive, targeted and high-intent leads...',
        link: '/services/ppc-advertising'
    },
    {
        icon: seo,
        title: 'SEO',
        description: 'Get more qualified traffic on the search terms that matter most to your business. We analyze every variable that affects SERPs...',
        link: '/services/seo'
    }],
    [
        {
            icon: ppc,
            title: 'PPC Advertising',
            description: 'Stop struggling with lead generation and marketing ideas. Digital Buzzers will deliver exclusive, targeted and high-intent leads...',
            link: '/services/ppc-advertising'
        },
        {
            icon: seo,
            title: 'SEO',
            description: 'Get more qualified traffic on the search terms that matter most to your business. We analyze every variable that affects SERPs...',
            link: '/services/seo'
        }, {
            icon: socialmedia,
            title: 'Social Media ',
            description: 'Every brand is different, so your social media management strategy should be as well. We prioritize a high level of attention to detail...',
            link: '/services/social-media'
        }],
    [
        {
            icon: seo,
            title: 'SEO',
            description: 'Get more qualified traffic on the search terms that matter most to your business. We analyze every variable that affects SERPs...',
            link: '/services/seo'
        }, {
            icon: socialmedia,
            title: 'Social Media ',
            description: 'Every brand is different, so your social media management strategy should be as well. We prioritize a high level of attention to detail...',
            link: '/services/social-media'
        },
        {
            icon: emailsms,
            title: 'Email & SMS Marketing',
            description: 'A direct line of communication with your customers and prospects can be your business’s most valuable asset. Our expertly-curated email...',
            link: '/services/email-sms-marketing'
        }],
        [
         {
                icon: socialmedia,
                title: 'Social Media ',
                description: 'Every brand is different, so your social media management strategy should be as well. We prioritize a high level of attention to detail...',
                link: '/services/social-media'
            },
            {
                icon: emailsms,
                title: 'Email & SMS Marketing',
                description: 'A direct line of communication with your customers and prospects can be your business’s most valuable asset. Our expertly-curated email...',
                link: '/services/email-sms-marketing'
            },
            {
                icon: contentCreation,
                title: 'Content Creation ',
                description: 'Every brand is different, so your social media management strategy should be as well. We prioritize a high level of attention to detail...',
                link: '/services/content-creation'
            }],
            [
                   {
                       icon: emailsms,
                       title: 'Email & SMS Marketing',
                       description: 'A direct line of communication with your customers and prospects can be your business’s most valuable asset. Our expertly-curated email...',
                       link: '/services/email-sms-marketing'
                   },
                   {
                       icon: contentCreation,
                       title: 'Content Creation ',
                       description: 'Every brand is different, so your social media management strategy should be as well. We prioritize a high level of attention to detail...',
                       link: '/services/content-creation'
                   },
                   {
                    icon: techServices,
                    title: 'Technical Services',
                    description: 'A direct line of communication with your customers and prospects can be your business’s most valuable asset. Our expertly-curated email...',
                    link: '/services/tech-services'
                }
            ],
]

const ServicesSlider = () => {
    const sliderLG = useRef(null);
    const sliderMD = useRef(null);
    const sliderSM = useRef(null);
    return (<>
        <div className='mt2'>
            <Row>
                <Col xs={0} md={0} lg={24} xl={24} xxl={24}>
                    <Row align={'middle'} gutter={[16, 8]}>
                        <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1}><LeftOutlined className='t1' onClick={() => sliderLG.current.prev()} /></Col>
                        <Col xs={0} sm={0} md={0} lg={21} xl={21} xxl={21}>
                            <Carousel dots={false} ref={sliderLG} speed={3000}>
                                {LG_AllServices.map(item =>
                                    <div>
                                        <Row gutter={[32, 32]} align='middle'>
                                            {item.map(data => <Col xs={0} sm={0} md={0} lg={8} xl={8} xxl={8}>
                                                <div>
                                                    <BasicCard
                                                        link={data.link}
                                                        img={data.icon}
                                                        description={data.description}
                                                        btntxt={data.title}
                                                    />
                                                </div>
                                            </Col>)}
                                        </Row>
                                    </div>)}
                            </Carousel>
                        </Col>
                        <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1}><RightOutlined className='t1' onClick={() => sliderLG.current.next()} /></Col>
                    </Row>
                </Col>
                <Col xs={0} md={24} lg={0} xl={0} xxl={0}>
                    <Row align={'middle'} gutter={[16, 8]}>
                        <Col xs={0} sm={0} md={2} lg={0} xl={0} xxl={0}><LeftOutlined className='t1' onClick={() => sliderMD.current.prev()} /></Col>
                        <Col xs={0} sm={0} md={20} lg={0} xl={0} xxl={0}>
                            <Carousel dots={false} ref={sliderMD} >
                                {MD_AllServices.map(item =>
                                    <div>
                                        <Row gutter={[16, 16]} align='middle'>
                                            {item.map(data => <Col xs={0} sm={0} md={12} lg={0} xl={0} xxl={0}>
                                                <div>
                                                    <BasicCard
                                                        link={data.link}
                                                        img={data.icon}
                                                        description={data.description}
                                                        btntxt={data.title}
                                                    />
                                                </div>
                                            </Col>)}
                                        </Row>
                                    </div>)}
                            </Carousel>
                        </Col>
                        <Col xs={0} sm={0} md={2} lg={0} xl={0} xxl={0}><RightOutlined className='t1' onClick={() => sliderMD.current.next()} /></Col>

                    </Row>
                </Col>
                <Col xs={24} md={0} lg={0} xl={0} xxl={0}>
                    <Row align={'middle'} gutter={[16, 8]}>
                        <Col xs={3} sm={3} md={0} lg={0} xl={0} xxl={0}><LeftOutlined className='t1' onClick={() => sliderSM.current.prev()} /></Col>
                        <Col xs={18} sm={18} md={0} lg={0} xl={0} xxl={0}>
                            <Carousel ref={sliderSM} dots={false}>
                                {AllServices.map(item =>
                                    <div>
                                        <Row gutter={[16, 16]} align='middle'>
                                            <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
                                                <BasicCard
                                                    link={item.link}
                                                    img={item.icon}
                                                    description={item.description}
                                                    btntxt={item.title}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </Carousel>
                        </Col>
                        <Col xs={3} sm={3} md={0} lg={0} xl={0} xxl={0}><RightOutlined className='t1' onClick={() => sliderSM.current.next()} /></Col>
                    </Row>
                </Col>
            </Row>
        </div>
    </>)
}
export default ServicesSlider;