
import { CheckOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import '../../App.css';
import './Services.css';
import '../../pages/AboutUs/AboutUs.css';
import webdesign from '../../img/Services/WebDesing/Exper-in-web-design.png';
import BasicCard from '../../Component/BasicCard/BasicCard';
import FreeConsultation from '../AboutUs/FreeConsultation';
import AboutUsSlider from '../AboutUs/AboutUsSlider';
import contentstrategy from '../../img/Services/contentcreation/Content-creaton.png';
import Contentwriting from '../../img/Services/contentcreation/Content-creaton.png';
import motiongrapicsanimation from '../../img/Services/contentcreation/Content-creaton.png';
import socialmediacontent from '../../img/Services/contentcreation/Content-creaton.png';
import videocontent from '../../img/Services/contentcreation/Content-creaton.png';
import infographics from '../../img/Services/contentcreation/Content-creaton.png';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
import { Helmet } from "react-helmet";

const ContentCreation=()=>{
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])
    return(<>
     <div className="page-banner contact-banner">
     <Helmet>
            <meta charSet="utf-8" />
            <title>Content Marketing Services Agency | Digital Buzzers</title>
            <meta name="Grow your business with unique content creation offered from Digital Buzzers. We are a leading Content Creation Management Company. Contact us today!"
                content="
                saas content marketing agency,
                content marketing agency near me,
                content marketing agency services,
                content marketing services agency,
                content marketing agencies uk,
                content marketing agencies london
                "/>
        </Helmet>
            <div className="t2 bold1-txt center mb">Content Creation</div>
            <div className="t5 center bold1-txt">We specialize in <span className="yellow-color">strategy, branding, visual design, content production, and digital communication.</span>  </div>
        </div>
        <Row className='box-shadow'>
            <Col xs={24} md={24} lg={12}>
                <img src={webdesign} height='100%' width='100%' alt='35'/>
            </Col>
            <Col xs={24} md={24} lg={12} className='data-creativity-content'>
                <div className='t4 bold1-txt mb2'>Experts in Media Design</div>
                <div className='t6 mb bold4-txt'>With a team of expert digital media designers we are a creative agency
which is into branding, content creation and advertising campaign.</div>
                <div className='t6 mb2 mt2 bold1-txt'>What does Digital Buzzer Specialize in?</div>
                <div className='t6 mb bold4-txt'> <span className='mr t5 bold1-txt green-color'><CheckOutlined /></span>Business-to-Consumer and Business-to-Business websites.</div>
                <div className='t6 mb bold4-txt'> <span className='mr t5 bold1-txt green-color'><CheckOutlined /></span>Ecommerce stores, integrations and conversion optimisation.</div>
                <div className='t6 mb bold4-txt'> <span className='mr t5 bold1-txt green-color'><CheckOutlined /></span>Web apps, databases and bespoke plugins and integrations.</div>
                <div className='t6 mb bold4-txt'> <span className='mr t5 bold1-txt green-color'><CheckOutlined /></span>Search Engine Optimisation and lead generation.</div>

                <Row align='middle' gutter={[8, 16]} className='mt2'>
                    <Col xs={24} md={7} lg={8}>
                        <button htmlType="submit" className='submit-btn services-web-design-btn t5 bold1-txt'><a href='/contact' className='darkblue-color services-web-design-btn-txt'>Contact Us</a></button>
                    </Col>
                    <Col xs={24} md={17} lg={16} >
                    <div className='t6 bold4-txt'> or call us on <a href="tel:9062758799" className="yellow-color" target={'_blank'}> (+91) 982 079 6941</a></div>
                    </Col>
                </Row>
            </Col>
        </Row>
               <div className='we-are-different'>
                <div className='t3 bold1-txt center mb'>What’s included?</div>
                <div className='t6 bold1-txt center mb2'>Every web design project is different, so we can’t define your complete feature list or exact pricing. However, these are our core ingredients for website success.</div>
                <br />
                <Row gutter={[32, 32]} >
                    <Col xs={24} md={12} lg={8}>
                        <BasicCard
                            img={contentstrategy}
                            description='This includes planning and developing a content strategy that aligns with the brands goals and target audience.'
                            title='Content Strategy'
                        />
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <BasicCard
                            img={Contentwriting}
                            description='This includes creating written content for blog posts, articles, white papers, and other marketing materials.'
                            title='Content Writing'
                        />
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <BasicCard
                            img={motiongrapicsanimation}
                            description='This includes creating eye-catching trending motion animated videos for branding.'
                            title='Motion Graphics/Animation'
                        />
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <BasicCard
                            img={socialmediacontent}
                            description='This includes creating social media posts that are engaging and shareable.'
                            title='Social Media Content'
                        />
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <BasicCard
                            img={videocontent}
                            description='This includes creating video content, such as product demos, tutorials, explainer videos, and more.'
                            title='Video Content'
                        />
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <BasicCard
                            img={infographics}
                            description='This includes designing infographics that visually communicate complex information in an easy-to-understand way.'
                            title='Infographics'
                        />
                    </Col>
                    {/* <Col xs={24} md={12} lg={8}>
                        <BasicCard
                            img={safeBox}
                            description='This includes promoting the content through various channels to reach the target audience.'
                            title='Content Promotion'
                        />
                    </Col> */}
                </Row>
            </div>
            <AboutUsSlider
            sliderTxt={[
                'I have been extremely impressed by the customer service, attention to detail and all-round performance of this fantastically well run company. I will be highly recommending to anyone and everyone!',
                'Digital Buzzers built us a beautiful ecommerce website with a wealth of custom features and layouts. The backend is super simple to use, and their marketing, advice and SEO offering have meant that we have hit the ground running and are already bringing in sales!',
                'They took us through the design process from concept to delivery for our new school website. We are extremely pleased with Digital Buzzers service.'
            ]} />
        <FreeConsultation />
        </>)
}
export default ContentCreation;