



import { Row, Col } from 'antd';
import '../../App.css';
import './Services.css';
import '../../pages/AboutUs/AboutUs.css';
import s1 from '../../img/Services/socialmedia/Boost-social-efforts-to-the-moon.png';
import s2 from '../../img/Services/socialmedia/establish-presence-on-key.png';
import BasicCard from '../../Component/BasicCard/BasicCard';
import FreeConsultation from '../AboutUs/FreeConsultation';
import AboutUsSlider from '../AboutUs/AboutUsSlider';
import  buildbrandawareness from '../../img/Services/socialmedia/Build-brand-awarness.png';
import reputationmangement from '../../img/Services/socialmedia/Reputation-management.png';
import expandyoueinfluence from '../../img/Services/socialmedia/Expand-influence.png';
import showcaseyourexpertise from '../../img/Services/socialmedia/Showcase-your-expertise.png';
import beintheconversation from '../../img/Services/socialmedia/Be-in-conversation.png';
import drivetraffictoyourwebsite from '../../img/Services/socialmedia/Drive-traffic-to-your-website.png';
import buildaloyalfollowing from '../../img/Services/socialmedia/Build-a-loyal-following.png';
import understandyourgrowth from '../../img/Services/socialmedia/Understand-your-growth.png';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
import { Helmet } from "react-helmet";

const SocialMedia=()=>{
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])
    return(<>
    <div className="page-banner contact-banner">
    <Helmet>
            <meta charSet="utf-8" />
            <title>Social Media Marketing Company in USA,UK | Digital Buzzers</title>
            <meta name="Digital Buzzers is a leading Social Media Marketing Company operating from USA & UK. Contact us today!"
                content="
                social media marketing agency for small business,
social media management company for small business,
social media agency for small business,
social media digital marketing agency usa,
social media marketing agency uk,
social media marketing companies uk,
social media marketing in usa

                "/>
        </Helmet>
            <div className="t2 bold1-txt center mb">Social Media Marketing</div>
            <div className="t5 center bold1-txt">Specialising in social media marketing services built on engaging creative and through strategic planning to create high performing, impactful creative digital marketing campaigns that get people talking</div>
        </div>
        <Row>
        <Col xs={24} md={0} lg={0}>
                <img src={s1} height='100%' width='100%' alt='43'/>
            </Col>
            <Col xs={24} md={24} lg={12} className='data-creativity-content'>
                <div className='t4 bold1-txt mb2'>Boost your social efforts to the moon</div>
                <div className='t6 mb bold4-txt'>We specialise in cohesive social media marketing services built on engaging creative and thorough strategic planning to create high performing, impactful creative digital marketing campaigns that get people talking.</div>
                <div className='t6 mb mt bold1-txt'>Our comprehensive solutions cover all aspects of your business’s social media presence:</div>
                <div className='t6 bold4-txt'> - Optimized profiles on each platform Instagram, Facebook. Linkedin, Twitter, Tiktok.</div>
                <div className='t6 bold4-txt'> - Monthly content calendars.</div>
                <div className='t6 bold4-txt'> - Custom graphics that elevate your brand.</div>
                <div className='t6 bold4-txt'> - Responding to post comments.</div>
                <div className='t6 bold4-txt'> - Boosted posts for increased visibility.</div>
                <div className='t6 bold4-txt'> - Organise your link easily using Linktree</div>
                <Row align='middle' gutter={[8, 16]} className='mt2'>
                    <Col xs={24} md={7} lg={8}>
                        <button htmlType="submit" className='submit-btn services-web-design-btn t5 bold1-txt'><a href='/contact' className='darkblue-color services-web-design-btn-txt'>Contact Us</a></button>
                    </Col>
                    <Col xs={24} md={17} lg={16} >
                    <div className='t6 bold4-txt'> or call us on <a href="tel:9062758799" className="yellow-color" target={'_blank'}> (+91) 982 079 6941</a></div>
                    </Col>
                </Row>
            </Col>
            <Col xs={0} md={24} lg={12}>
                <img src={s1} height='100%' width='100%' alt='44'/>
            </Col>
        </Row>
        <Row className='box-shadow'>
        <Col xs={24} md={24} lg={12}>
                <img src={s2} height='100%' width='100%' alt='45'/>
            </Col>
            <Col xs={24} md={24} lg={12} className='data-creativity-content'>
                <div className='t4 bold1-txt mb2'>Establish your presence on key social media channels</div>
                <div className='t6 mb bold4-txt'>What better way to increase organic reach, impressions, and
make sure your audience is heard than with active engagement?
Our social media management solutions go beyond creating
impressive profiles. We also offer extensive engagement to put your
brand in the middle of the conversation. We'll show your followers
that you not only walk the walk, but you also talk the talk..with
them!Well executed engagement</div>
<div className='t6 mb bold4-txt'>will not only support higher organic reach, but will also support
your customer service and make a huge difference in your overall
brand reputation.</div>
                <Row align='middle' gutter={[8, 16]} className='mt2'>
                    <Col xs={24} md={9} lg={10}>
                        <button htmlType="submit" className='submit-btn services-web-design-btn t5 bold1-txt'><a href='/contact' className='darkblue-color services-web-design-btn-txt'>Free Consulation</a></button>
                    </Col>
                    <Col xs={24} md={15} lg={14} >
                    <div className='t6 bold4-txt'> or call us on <a href="tel:9062758799" className="yellow-color" target={'_blank'}> (+91) 982 079 6941</a></div>
                    </Col>
                </Row>
            </Col>
        </Row>
           <div className='we-are-different'>
                <div className='t3 bold1-txt center mb'>What’s included?</div>
                {/* <div className='t6 bold1-txt center mb2'>Every web design project is different, so we can’t define your complete feature list or exact pricing. However, these are our core ingredients for website success.</div> */}
                <br />
                <Row gutter={[32, 32]}>
                    <Col xs={24} md={8} lg={6}>
                        <BasicCard
                            img={buildbrandawareness}
                            description='Engage with your target audience on the social media platforms they go to most often'
                            title='Build Brand Awareness'
                        />
                    </Col>
                    <Col xs={24} md={8} lg={6}>
                        <BasicCard
                            img={reputationmangement}
                            description='Control the conversation by telling your own brands story'
                            title='Reputation Mangement'
                        />
                    </Col>
                    <Col xs={24} md={8} lg={6}>
                        <BasicCard
                            img={expandyoueinfluence}
                            description='Acquire thousands of advocates constantly waiting for your content.'
                            title='Expand Your Influence'
                        />
                    </Col>
                    <Col xs={24} md={8} lg={6}>
                        <BasicCard
                            img={showcaseyourexpertise}
                            description='Maxinize the exposure of your companys most impressive work'
                            title='Showcase Your Expertise'
                        />
                    </Col>
                    <Col xs={24} md={8} lg={6}>
                        <BasicCard
                            img={beintheconversation}
                            description='Bring your brand to the forfront of trending topics and industry news while engaging with your followers.'
                            title='Be In The Conversation'
                        />
                    </Col>
                    <Col xs={24} md={8} lg={6}>
                        <BasicCard
                            img={drivetraffictoyourwebsite}
                            description='Use social media channels to drive customers directly to your site and benefit your business as a whole.'
                            title='Drive Traffic To Your Website'
                        />
                    </Col>
                    <Col xs={24} md={8} lg={6}>
                        <BasicCard
                            img={buildaloyalfollowing}
                            description='Turn your customers into followers who organically spread your message.'
                            title='Build A Loyal Following'
                        />
                    </Col>
                    <Col xs={24} md={8} lg={6}>
                        <BasicCard
                            img={understandyourgrowth}
                            description='See whats working (and do more of it!) with detailed real time reports.'
                            title='Understand Your Growth'
                        />
                    </Col>
                </Row>
            </div>
            <AboutUsSlider
            sliderTxt={[
                'I have been extremely impressed by the customer service, attention to detail and all-round performance of this fantastically well run company. I will be highly recommending to anyone and everyone!',
                'Digital Buzzers built us a beautiful ecommerce website with a wealth of custom features and layouts. The backend is super simple to use, and their marketing, advice and SEO offering have meant that we have hit the ground running and are already bringing in sales!',
                'They took us through the design process from concept to delivery for our new school website. We are extremely pleased with Digital Buzzers impeccable service.'
            ]} />
        <FreeConsultation />
    </>)
}
export default SocialMedia;