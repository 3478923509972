import '../../App.css';
import '../../pages/Home/Home.css';
import './BasicCard.css';

const BasicCard = ({ link, img, description, btntxt,title }) => {
    return (<div className='basiccard'>
        <a href={link}>
            <div className='basic-card darkblue-color'>
                <div className='service-icon' style={{ paddingTop: 0 ,transform:'scale(0.7)',textAlign:'center'}}>   <img src={img} className='icon-size' alt='1'/> </div>
                {title && <div className='t5 bold1-txt center mb'>{title}</div>}
                <div className="t7 center bold1-txt" style={{color:'#0a114a'}}>{description}</div>
                {btntxt && <button htmlType="submit" className='blue-white-btn white-color bold1-txt t7 mb2'>{btntxt}</button>}
            </div>
        </a>
    </div>)
}
export default BasicCard;