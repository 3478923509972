import { Row, Col } from 'antd';
import '../../App.css';
import './Services.css';
import '../../pages/AboutUs/AboutUs.css';
import seo from '../../img/Services/seo/SEO.png';
import FreeConsultation from '../AboutUs/FreeConsultation';
import AboutUsSlider from '../AboutUs/AboutUsSlider';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
import { Helmet } from "react-helmet";

const SEO = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])
    return (<div className="darkblue-color">
         <Helmet>
            <meta charSet="utf-8" />
            <title>Best SEO Company | Digital Buzzers | USA | UK</title>
            <meta name="Rank your website with Digital Buzzers! We are a leading seo services company with high ranking performance with our operations in UK & USA."
                content="
                seo advertising companies,
                digital marketing seo services,
                seo advertising agency,
                local seo agency near me,
                seo internet marketing services,
                search engine optimisation uk,
                recommended seo companies uk,
                search engine optimisation company in uk,
                search engine optimization agency uk,
                search engine optimization companies uk,
                search engine optimization company in uk,
                seo agency london uk
                
                "/>
        </Helmet>
        <div className="page-banner contact-banner">
            <div className="t2 bold1-txt center mb">Search Engine Optimisation</div>
            <div className="t5 center bold1-txt">SEO is crucial for being  <span className="yellow-color">found on Google</span>. We help you rank organically for search phrases relevant to your industry.</div>
        </div>

        <Row>
            <Col xs={24} md={24} lg={12}>
                <img src={seo} height='100%' width='100%' alt='42'/>
            </Col>
            <Col xs={24} md={24} lg={12} className='data-creativity-content'>
                <div className='t4 bold1-txt mb2'>What is SEO?</div>
                <div className='t6 mb bold4-txt'>Search Engine Optimisation is crucial for any new website trying to break through the competition or an already established website looking to appear higher and increase sales.</div>
                <div className='t6 mb bold4-txt'>Optimising a website is a mix of technical changes, research, link building and content creation. All of this results in the website showing higher in search results for phrases used by your target market.</div>
                <div className='t6 mb bold4-txt'>At Digital Buzzers, we use a proven and structured SEO approach to help businesses climb to the first page of Google!</div>
                <Row align='middle' gutter={[8, 16]} className='mt2'>
                    <Col xs={24} md={7} lg={8}>
                        <button htmlType="submit" className='submit-btn services-web-design-btn t5 bold1-txt'><a href='/contact' className='darkblue-color services-web-design-btn-txt'>Contact Us</a></button>
                    </Col>
                    <Col xs={24} md={15} lg={16} >
                    <div className='t6 bold4-txt'> or call us on <a href="tel:9062758799" className="yellow-color" target={'_blank'}> (+91) 982 079 6941</a></div>
                    </Col>
                </Row>
            </Col>
        </Row>
        <div className='plus-all-services'>
            <div className='t4 bold1-txt center mb2'>Want to rank higher in Google?</div>
            <div className='t6 center bold4-txt mb2'>We use a combination of off-site and on-site SEO to help you rank higher in SERPs (Search Engine Result Pages). Below is a brief outline of what you can expect from our Search Engine Optimisation service.</div>
            <br />
            <Row gutter={[32, 8]}>
                <Col md={24} lg={12} >
                    <div className='webdesign-process'>
                        <div className='t5 bold1-txt mb'>SEO Audit</div>
                        <div className='t6'>An in-depth SEO audit is the starting point of any SEO strategy. A Digital Buzzers SEO expert will evaluate your website’s strengths and weaknesses and will provide a list of detailed recommendations.</div>
                    </div>
                </Col>
                <Col md={24} lg={12}>
                    <div className='webdesign-process p2'>
                        <div className='t5 bold1-txt mb'>Keyword Research</div>
                        <div className='t6'>Undertaking keyword research is a vital part of any SEO project. We’ll analyse any existing keywords on your site and will also identify any new or replacement keywords for pages throughout the site to maximise success.</div>
                    </div>
                </Col>
                <Col md={24} lg={12} >
                    <div className='webdesign-process'>
                        <div className='t5 bold1-txt mb'>Technical SEO</div>
                        <div className='t6'>Technical SEO focuses on the code aspects of your website. Issues with the technical parts of the site may be limiting your site’s ranking abilities, which is why it’s vital to address these as early on as possible.</div>
                    </div>
                </Col>
                <Col md={24} lg={12}>
                    <div className='webdesign-process p2'>
                        <div className='t5 bold1-txt mb'>Mobile SEO</div>
                        <div className='t6'>Google now ranks websites based if the site is responsive, how quick it is, how accessible it is and whether it’s easy to use on a <b>phone</b>. Google shows different results on a desktop than it does on mobile, so it’s vital that you have all ends covered!</div>
                    </div>
                </Col>
                <Col md={24} lg={12} >
                    <div className='webdesign-process'>
                        <div className='t5 bold1-txt mb'>Local SEO</div>
                        <div className='t6'>With more than 50% of local searches coming from mobile devices, it’s essential that your SEO strategy is well planned. Local SEO is competitive, so it’s vital that you accurately communicate your location to get the best shot at appearing in local results.</div>
                    </div>
                </Col>
                <Col md={24} lg={12}>
                    <div className='webdesign-process p2'>
                        <div className='t5 bold1-txt mb'>Content Creation</div>
                        <div className='t6'>Our Search Engine Optimisation experts can write SEO optimised website content, blog articles and dedicated landing pages for your website to ensure that your site reflects what your users are searching for in Google.</div>
                    </div>
                </Col>
            </Row>
        </div>
        <AboutUsSlider
            sliderTxt={[
                'I have been extremely impressed by the customer service, attention to detail and all-round performance of this fantastically well run company. I will be highly recommending to anyone and everyone!',
                'Digital Buzzers built us a beautiful ecommerce website with a wealth of custom features and layouts. The backend is super simple to use, and their marketing, advice and SEO offering have meant that we have hit the ground running and are already bringing in sales!',
                'They took us through the design process from concept to delivery for our new school website. We are extremely pleased with Digital Buzzers service.'
            ]} />
        <FreeConsultation />
    </div>)
}

export default SEO;