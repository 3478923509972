import { useRef } from 'react';
import { Row, Col, Carousel } from 'antd';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import '../../App.css';
import './AboutUs.css';

const AboutUsSlider = ({ sliderTxt }) => {
    const slider = useRef(null);

    return (<>
        <div className='aboutus-slider'>
            <Row align='middle' gutter={[16, 16]} >
                <Col span={3} align='start'><LeftCircleOutlined className='aboutus-leftright-arrow t1 bold1-txt' onClick={() => slider.current.prev()} /></Col>
                <Col span={18}>
                    <Carousel ref={slider} dots={false}>
                        {sliderTxt.map(item => {
                            return (<>
                                <div className='t4 bold4-txt center white-color' style={{fontStyle:'italic'}}>{item}</div>
                            </>)
                        })}
                    </Carousel>
                </Col >
                <Col span={3} align='end'><RightCircleOutlined className='aboutus-leftright-arrow t1 bold1-txt' onClick={() => slider.current.next()} /></Col>
            </Row>
        </div>
    </>)
}
export default AboutUsSlider;