
import { Row, Col } from 'antd';
import { Helmet } from "react-helmet";
import '../../App.css';
import './Services.css';
import '../../pages/AboutUs/AboutUs.css';
import t1 from '../../img/Services/techservices/Team-of-expert-web-developers.png';
import BasicCard from '../../Component/BasicCard/BasicCard';
import FreeConsultation from '../AboutUs/FreeConsultation';
import AboutUsSlider from '../AboutUs/AboutUsSlider';
import Highqualityandcleancode from '../../img/Services/techservices/High-quality-clean-code.png'
import Optimisedforspeed from '../../img/Services/techservices/Optimised-for-speed.png'
import Tightdeadline from '../../img/Services/techservices/Tight-deadline.png'
import Ongoingsupport from '../../img/Services/techservices/Support-and-maintenance.png'
import Notemplates from '../../img/Services/techservices/No-templates.png'
import Whitelabelled from '../../img/Services/techservices/White-labelled.png'
import ReactGA from 'react-ga';
import { useEffect } from 'react';

const TechService = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])
    return (<>
      <Helmet>
            <meta charSet="utf-8" />
            <title>Mobile App & Software Development Company | Digital Buzzers</title>
            <meta name="Boost your business with Best Mobile app & Software Development Services operating from USA,UK. Get a quote today!"
                content="
                software development company in uk,
top app development companies in usa,
software development agency london,
mobile development company in usa

                "/>
        </Helmet>
        <div className="page-banner contact-banner">
            <div className="t2 bold1-txt center mb">Tech Services</div>
            <div className="t5 center bold1-txt"> <span className="yellow-color">Software and App</span>  design and development for IT businesses and web design agencies.</div>
        </div>
        <Row className='box-shadow'>
            <Col xs={24} md={24} lg={12}>
                <img src={t1} height='100%' width='100%' alt='46'/>
            </Col>
            <Col xs={24} md={24} lg={12} className='data-creativity-content'>
                <div className='t4 bold1-txt mb2'>Team of Expert Web Developers</div>
                <div className='t6 mb bold4-txt'>No matter the size or budget, our experienced team of WordPress and WooCommerce
                    developers are here to tackle any challenge!</div>
                <div className='t6 mb bold4-txt'>Whether you're looking to distribute your workload, need cover for a team member or
                    need a fresh pair of eyes, the Digital Buzzers development team can step in immediately
                    and deliver efficient and high-quality code.</div>
                <div className='t6 mb bold4-txt'>Our developers and their years of WordPress and WooCommerce experience are available
                    on-demand, with the latest technology and best practices in their toolkit.
                </div>

                <Row align='middle' gutter={[8, 16]} className='mt2'>
                    <Col xs={24} md={7} lg={8}>
                        <button htmlType="submit" className='submit-btn services-web-design-btn t5 bold1-txt'><a href='/contact' className='darkblue-color services-web-design-btn-txt'>Contact Us</a></button>
                    </Col>
                    <Col xs={24} md={17} lg={16} >
                    <div className='t6 bold4-txt'> or call us on <a href="tel:9062758799" className="yellow-color" target={'_blank'}> (+91) 982 079 6941</a></div>
                    </Col>
                </Row>
            </Col>
        </Row>
        <div className='plus-all-services'>
            <div className='t2 bold1-txt center mb2'>What can we help with?</div>
            <br />
            <Row gutter={[64, 16]}>
                <Col md={24} lg={12} >
                    <div className='webdesign-process'>
                        <div className='t4 bold1-txt mb2'>WordPress development</div>
                        <div className='t6 mt'>We love WordPress and have been providing white label development since our
                            beginnings. With this being our leading service, we can create custom themes and
                            plugins — no matter the complexity!</div>
                    </div>
                </Col>
                <Col md={24} lg={12}>
                    <div className='webdesign-process p2'>
                        <div className='t4 bold1-txt mb2'>WooCommerce development</div>
                        <div className='t6 mt'>Whether you need a WooCommerce theme or a whole integration with third-party
                            software — we're your team! We've worked with WooCommerce since the early days
                            and have produced well coded, complex stores and integrations.</div>
                    </div>
                </Col>
                <Col md={24} lg={12} >
                    <div className='webdesign-process'>
                        <div className='t4 bold1-txt mb2'>Front-end development</div>
                        <div className='t6 mt'>HTMLS and CSS3 are a part of everything that we do, so it’s not hard to figure out
                            that you can find help from us with responsive sites too!</div>
                    </div>
                </Col>
                <Col md={24} lg={12}>
                    <div className='webdesign-process p2'>
                        <div className='t4 bold1-txt mb2'>Email template development</div>
                        <div className='t6 mt'>Need a custom email template? Whether it's for MailChimp or another system, we
                            take pride in creating email templates that work in every email client.</div>
                    </div>
                </Col>
                <Col md={24} lg={12} >
                    <div className='webdesign-process'>
                        <div className='t4 bold1-txt mb2'>WordPress support</div>
                        <div className='t6 mt'>White labelled support, giving your clients access to a support centre, branded email
                            and even a phone number so that we can quickly fix the problem.</div>
                    </div>
                </Col>
                <Col md={24} lg={12}>
                    <div className='webdesign-process p2'>
                        <div className='t4 bold1-txt mb2'>Managed hosting</div>
                        <div className='t6 mt'>Completely white labelled and managed hosting; including shared cPanel hosting
                            and dedicated virtual servers. Comes with a dashboard to track support requests</div>
                    </div>
                </Col>
            </Row>
        </div>
        <div className='we-are-different'>
            <div className='t4 bold1-txt center mb'>Why partner with Digital Buzzers </div>
            <br />
            <Row gutter={[32, 32]} >
                <Col xs={24} md={12} lg={8}>
                    <BasicCard
                        img={Highqualityandcleancode}
                        description='Every experienced or fear poor quality code from freelancers or outsourcing agencies? You will be delighted to see that our code is well structured, commented and extendible for future additions.'
                        title='High quality & clean code'
                    />
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <BasicCard
                        img={Optimisedforspeed}
                        description='Every website is hand developed using latest standards
                        that are not only secure but also faster. We develop and
                        optimise our websites from the ground up to ensure that
                        it is running as quickly and smoothly as possible.'
                        title='Optimised for speed'
                    />
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <BasicCard
                        img={Tightdeadline}
                        description='Need something completed quickly and can not fit it into
                        your schedule? Rest assured we will always find a gap in our
                        schedule to complete your request or project.'
                        title='Tight deadline?
                        '
                    />
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <BasicCard
                        img={Ongoingsupport}
                        description='Whether you are a direct customer or an agency partner,
                        we offer free maintenance, support and bug fixes for the
                        first 2 months after project completion.'
                        title='Ongoing support'
                    />
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <BasicCard
                        img={Notemplates}
                        description='We do not use any premade themes or templates.
                        Everything we create is 100% bespoke and original;
                        created by our expert in-house team so that it is what will
                        work best for your business.'
                        title='No templates'
                    />
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <BasicCard
                        img={Whitelabelled}
                        description='Everything is completely white labelled. Whether we are
                        making a complete website or providing your customers
                        with hosting and support, they will always think they are
                        dealing with your business.'
                        title='White labelled'
                    />
                </Col>
            </Row>
        </div>
        <AboutUsSlider
            sliderTxt={[
                'I have been extremely impressed by the customer service, attention to detail and all-round performance of this fantastically well run company. I will be highly recommending to anyone and everyone!',
                'Digital Buzzers built us a beautiful ecommerce website with a wealth of custom features and layouts. The backend is super simple to use, and their marketing, advice and SEO offering have meant that we have hit the ground running and are already bringing in sales!',
                'They took us through the design process from concept to delivery for our new school website. We are extremely pleased with Digital buzzers impeccable service.'
            ]} />
        <FreeConsultation />
    </>)
}
export default TechService;