import React, { useState ,useEffect} from 'react';
import {Helmet} from "react-helmet";
import { UserOutlined, MailOutlined, PhoneOutlined, LinkedinFilled , FacebookFilled, ClockCircleOutlined,InstagramFilled } from '@ant-design/icons';
import { Form, Input, Col, Row, Spin, Modal, message } from 'antd';
import ReactGA from 'react-ga';
import axios from "axios";
import './Home.css';
import '../../App.css';
import wave from '../../img/Home/waves.svg';
import webdesign from '../../img/Home/web-design.webp';
import whatIsCreatives from '../../img/Home/whatIsCreatives.jpeg';
import Section from '../../Component/Section/Section';
import ServicesSlider from './ServicesSlider';
import aboutcompany from '../../img/Home/About-company.png';
import contactus from '../../img/Home/Contact-us.png';
import portfolio from '../../img/Home/portfolio.png';
import texticon from '../../img/Home/texticon.png';
import BasicCard from '../../Component/BasicCard/BasicCard';

const Home = () => {
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])

    // --------------contact form----------

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const submitform = (val) => {
        setLoading(true);
        var formData = new FormData();
        Object.keys(val).map(item => {
            formData.append(item, val[item]);
        })
        axios
            .post("https://script.google.com/macros/s/AKfycbyB4lx-g1L2xYE7IfKzjQ6ha-r5HiwaMzVjAS4F9Ee8Q9Yb2wgLNR0-c1DLZm9tF_wf/exec", formData)
            .then(function (response) {
                setLoading(false);
                ReactGA.event({
                    category: val.name,
                    action: val.number,
                    label: val.message,
                    value: val.email
                });
                success();
                setIsModalOpen(false);
            })
            .catch(function (error) {
                setLoading(false);
                errorMsg();
            });

    }
    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Thank you for considering Digital Buzzers for your digital growth. We will contact you within one business day.',
        });
    };

    const errorMsg = () => {
        messageApi.open({
            type: 'error',
            content: 'Something went wrong please try again.... ',
        });
    };

    const GetStartedForm = () => {
        return (
            <Spin spinning={loading}>
                <Form onFinish={submitform} className='home-page-form'>
                    <Form.Item
                        name="Name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your full name!',
                            },
                        ]}
                    >
                        <Input prefix={<UserOutlined className='t5 mr' />} placeholder="Full Name" className='t5' />
                    </Form.Item>
                    <Form.Item
                        name="Email"
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                required: true,
                                message: 'Please enter your E-mail!',
                            },
                        ]}
                    >
                        <Input prefix={<MailOutlined className='t5 mr' />} placeholder="Email Address" className='t5' />
                    </Form.Item>
                    <Form.Item
                        name="Number"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter number!',
                            },
                            {
                                required: true,
                                pattern: /^[\d]{10}$/,
                                message: "Value should contain just number",
                            },

                        ]}
                    >
                        <Input prefix={<PhoneOutlined className='t5 mr' />} placeholder="Phone Number" className='t5' />
                    </Form.Item>
                    <Row justify={'center'}>
                        <Form.Item>
                            <button htmlType="submit" className='submit-btn t4 bold1-txt'>Get started</button>
                        </Form.Item>
                    </Row>
                </Form>
            </Spin>
            )
    }
    // ------------------------------------------------------------
    const setVisibilityHidden = () => {
        document.getElementById('p1').style.visibility = 'hidden';
    }

    const setVisibilityVisible = () => {
        document.getElementById('p1').style.visibility = 'visible';
    }
    const setVisibilityHiddenp2 = () => {
        document.getElementById('p2').style.visibility = 'hidden';
    }

    const setVisibilityVisiblep2 = () => {
        document.getElementById('p2').style.visibility = 'visible';
    }
    return (<>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Leading Digital Marketing Company | Digital Buzzers</title>
            <meta name="We are leading Digital Marketing & software development Service Provider in UK & US. Our team experts help you to grow your business. Get a quote today!"
             content="
             digital marketing company in usa,
digital marketing agency in usa,
top digital marketing agencies in usa,
best digital marketing agency in usa,
digital marketing services in usa,
social media marketing agency usa,
best digital marketing company in usa,
top digital marketing companies in usa,
digital marketing agency united states,
digital marketing united states,
digital marketing firms in usa,
digital marketing services for small business,
full service digital marketing agencies,
local digital marketing agencies,
digital marketing for business,
digital media marketing agency,
best digital marketing agency websites,
digital media marketing company,
best digital marketing services,
top 10 digital marketing companies,
digital marketing services agency,
digital marketing consulting services,
top 10 digital marketing agency,
digital marketing agency services,
digital marketing company services,
top digital marketing firms,
small business digital marketing services,
digital marketing services in uk,
digital marketing company in uk,
digital marketing agency in uk,
digital marketing uk,
digital marketing agency for small business uk,
digital marketing companies london,
best digital marketing agency in uk,
advertising agencies uk,
best digital marketing agency in london,
best digital marketing company in uk,
digital marketing agency england,
uk social media agency,
top uk marketing agencies,
digital marketing agency london uk,
digital marketing consultant london,
digital marketing service in uk,
top digital marketing agencies in london,
digital agency united kingdom,
digital marketing in the uk,
digital agency london uk,
top digital agencies in london,
social media agency united kingdom
             " />

            </Helmet>
        <div className='home-banner'>
            <h1 className='t2-withspace white-color bold1-txt home-banner-txt'>A full-service agency that gets incredible <span className='yellow-color'>digital results!</span></h1>
            <button className='submit-btn t6 bold1-txt' onClick={showModal}>Custom Website Design</button>
        </div>
        <div className='wave'>
            <img src={wave} width='100%' alt='32'/>
        </div>
        {contextHolder}
        <Modal
            open={isModalOpen}
            footer={null}
            onCancel={handleCancel}
            centered>
            <GetStartedForm />
        </Modal>

        <div className='darkblue-color hb1 center'>
            <div className=' t3 mb2 bold1-txt'>The agency you've been looking for</div>
            <div className='t5 mb2'>Branding & Creative | Digital & Web Design <br /> Advertising & Content Creation | Software & App Development</div>
            <a href='/contact'><button className='blue-white-btn white-color bold1-txt t5'>Grow My Business</button></a>
        </div>
        <Section
            title='We turn ideas into extraordinary digital products'
            content={['We help businesses create breakthrough digital products and experiences. Websites should provide much more than just appealing design.', 'With the ideal ratio of SEO and digital marketing, you need a website that is optimized for conversion, speed, and security.']}
            btntxt='Web Design'
            subtitle='or call us on'
            linktxt='(+91) 982 079 6941'
            btnlink='/services/web-design/'
            link=''
            image={webdesign}
            leftImg={true}
            descriptionSpan={16}
        />
        <Row justify={'center'}>
            <Col xs={24} md={7} lg={6} className='project1' onMouseEnter={setVisibilityHidden} onMouseLeave={setVisibilityVisible}>
                <div className='project-section' id='p1'>
                    <a href="https://www.flometal.com/" target='_blank' alt=''>
                        <div className='t6 gray-color'> RECENT PROJECT</div>
                        <div className='t5 white-color bold1-txt mb'>Flo Pipes & Fittings</div>
                    </a>
                </div>
            </Col>
            <Col xs={24} md={10} lg={12}>
                <div className='project-section project-info'>
                    <div className='t3 darkblue-color bold1-txt mb2 '>
                        We use data, technology and creativity to help build your brand.
                    </div>
                    <div style={{ width: '35%', margin: '0 auto' }}><img alt='31' src={texticon} width='100%' /></div>
                </div>
            </Col>
            <Col xs={24} md={7} lg={6} className='project2' onMouseEnter={setVisibilityHiddenp2} onMouseLeave={setVisibilityVisiblep2}>
                <div className='project-section' id='p2'>
                    <a href="https://isngs.com/digital-marketing/index.html" target='_blank' alt=''>
                        <div className='t6 gray-color'> RECENT PROJECT</div>
                        <div className='t5 white-color bold1-txt mb'>Landing Page For Contractors</div>
                    </a>
                </div>
            </Col>
        </Row>
        <Section
            title='What is DIGITAL BUZZERS?'
            content={['We are a digital marketing and web company that focuses on result driven strategies. We offer a hassle-free, all-inclusive experience for companies wishing to develop online significantly.',
                ' We are an approachable, trustworthy, and highly skilled company that provides world-class measurable outcomes through unique commercial and e-commerce websites.',
                `Are you prepared to start achieving actual financial success? Let's talk...`]}
            btntxt='Get in touch'
            subtitle='or say "hi" on'
            linktxt={<div style={{ display: 'inline' }}><FacebookFilled className='mr ml' /></div>}
            link2txt={<div style={{ display: 'inline' }}><LinkedinFilled  /></div>}
            link3txt={<div style={{ display: 'inline' }}><InstagramFilled /></div>}
            link="https://www.facebook.com/profile.php?id=100091566953349&mibextid=LQQJ4d"
            link2="https://www.linkedin.com/company/digital-buzzers/"
            btnlink='/contact'
            image={whatIsCreatives}
            leftImg={false}
            descriptionSpan={20}
        />
        <div className='darkblue-color services'>
            <div className='t4 bold1-txt mb'>We’re unlike any web design company</div>
            <div className='t6 bold1-txt'>Looking to grow? You’re in the right place. Our creative, technical and sales experts will take your project from concept to delivery, focusing on taking your sales revenue to the next level.</div>
            <br />
            <ServicesSlider />
        </div>
        <div className='darkblue-color center blogs-info'>
            <div className='t3 bold1-txt mb'>Read our latest blog articles</div>
            <div className='t6 bold1-txt'>Whenever we’re not working on web design, ecommerce or SEO projects from our web design studio, we love sharing our web design and business knowledge! Below is a selection of our most recent blog articles.</div>
        </div>
        <Row className=' t6 white-color bold1-txt box-shadow'>
            <Col xs={24} md={12} lg={5}>
            <a href='/resources/blogs/google-ads' style={{color:'white'}}>
                <div id='b1' className='b1 ' onMouseEnter={()=>{
                    document.getElementById('b1').style.display='none';
                    document.getElementById('b1-new').style.display='block';
                }}>
                    <div className='blog-title'>
                        <span className='t5'>Guide To Google Ads</span> <br /><ClockCircleOutlined className='yellow-color mr' /> 5 minute to read</div>
                </div>
                <div id='b1-new' className='b1-new' onMouseLeave={()=>{
                    document.getElementById('b1').style.display='block';
                    document.getElementById('b1-new').style.display='none';
                }}>
                </div>
                </a>
            </Col>
            <Col xs={24} md={12} lg={5}>
            <a href='/resources/blogs/sms-marketing' style={{color:'white'}}>
                <div id='b2' className='b2 'onMouseEnter={()=>{
                    document.getElementById('b2').style.display='none';
                    document.getElementById('b2-new').style.display='block';
                }}>
                    <div className='blog-title'><span className='t5'>Why You Should Be Using SMS Marketing?</span><br /><ClockCircleOutlined className='yellow-color mr' /> 15 minute to read</div>
                </div>
                 <div id='b2-new' className='b2-new'onMouseLeave={()=>{
                    document.getElementById('b2').style.display='block';
                    document.getElementById('b2-new').style.display='none';
                }}>
                </div>
                </a>
            </Col>
            <Col xs={24} md={12} lg={5}>
            <a href='/resources/blogs/power-of-seo' style={{color:'white'}}>
                <div id='b5' className='b5'onMouseEnter={()=>{
                    document.getElementById('b5').style.display='none';
                    document.getElementById('b5-new').style.display='block';
                }}>
                    <div className='blog-title'><span className='t5'>Unlocking the Power of SEO</span><br /><ClockCircleOutlined className='yellow-color mr' /> 10 minute to read</div>
                </div>
                <div id='b5-new' className='b5-new' onMouseLeave={()=>{
                    document.getElementById('b5').style.display='block';
                    document.getElementById('b5-new').style.display='none';
                }}>
                </div>
                </a>
            </Col>
            <Col xs={24} md={12} lg={5}>
            <a href='/resources/blogs/digital-marketing' style={{color:'white'}}>
                <div id='b3' className='b3 'onMouseEnter={()=>{
                    document.getElementById('b3').style.display='none';
                    document.getElementById('b3-new').style.display='block';
                }}>
                    <div className='blog-title'><span className='t5'>The Ultimate Digital Marketing Strategy Guide</span><br /><ClockCircleOutlined className='yellow-color mr' /> 9 minute to read</div>
                </div>
                <div id='b3-new' className='b3-new' onMouseLeave={()=>{
                    document.getElementById('b3').style.display='block';
                    document.getElementById('b3-new').style.display='none';
                }}>
                </div>
                </a>
            </Col>
            <Col xs={24} md={12} lg={4}>
            <a href='/resources/blogs/social-media-marketing' style={{color:'white'}}>
                <div id='b4' className='b4'onMouseEnter={()=>{
                    document.getElementById('b4').style.display='none';
                    document.getElementById('b4-new').style.display='block';
                }}>
                    <div className='blog-title'><span className='t5'>Social Media Marketing Tips</span><br /><ClockCircleOutlined className='yellow-color mr' /> 7 minute to read</div>
                </div>
                <div id='b4-new' className='b4-new' onMouseLeave={()=>{
                    document.getElementById('b4').style.display='block';
                    document.getElementById('b4-new').style.display='none';
                }}>
                </div>
                </a>
            </Col>
        </Row>
        {/* <div className='blogs t6 white-color bold1-txt'>
            <div className='b1 '>
                <div className='blog-title'> Guide To Google Ads <br /><ClockCircleOutlined className='yellow-color mr' /> 7 minute to read</div>
            </div>
            <div className='b2 '>
                <div className='blog-title'>Why You Should Be Using SMS Marketing?<br /><ClockCircleOutlined className='yellow-color mr' /> 7 minute to read</div>
            </div>
            <div className='b3 '>
                <div className='blog-title'>The Ultimate Digital Marketing Strategy Guide<br /><ClockCircleOutlined className='yellow-color mr' /> 7 minute to read</div>
            </div>
            <div className='b4 '>
                <div className='blog-title'>Social Media Marketing Tips - Blog<br /><ClockCircleOutlined className='yellow-color mr' /> 7 minute to read</div>
            </div>
            <div className='b5 '>
                <div className='blog-title'>Unlocking the Power of SEO<br /><ClockCircleOutlined className='yellow-color mr' /> 7 minute to read</div>
            </div>
        </div> */}
        <div className='center ready-to-start'>
            <div className='t4 bold3-txt mb'>Ready to build incredible websites for your business?</div>
            <div className='t6 white-blue-color'>We research, plan, design, develop and support bespoke web design projects for national and international clients. Start your digital transformation and growth with us today. Whenever we’re not working on web design, ecommerce or SEO projects from our web design studio, we love sharing our web design and business knowledge!</div>
        </div>
        <div className='darkblue-color services '>
            <Row gutter={[32, 32]} align='middle'>
                <Col xs={24} md={12} lg={8}>
                    <BasicCard
                        link='/contact'
                        img={contactus}
                        description='Have any questions or are you ready to get rolling? Speak to our website design experts, today.'
                        btntxt='Contact Us'
                    />
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <BasicCard
                        link='/about-us'
                        img={aboutcompany}
                        description='Looking to improve your digital presence? Read our digital resources – available for free, online and to anyone!'
                        btntxt='About Company'
                    />
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <BasicCard
                        link='/our-work'
                        img={portfolio}
                        description='Browse our web design portfolio and see how we helped businesses transform their digital presence.'
                        btntxt='Portfolio'
                    />
                </Col>
            </Row>
        </div>
    </>)
}
export default Home;
