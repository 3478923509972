import ppc1 from '../../img/Services/ppc1.png';
import ppc2 from '../../img/Services/ppc2.png';
import FreeConsultation from "../AboutUs/FreeConsultation";
import { Row, Col } from 'antd';
import AboutUsSlider from "../AboutUs/AboutUsSlider";
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { useEffect } from 'react';

const PPCAdvetising = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])
    return (<div className="darkblue-color">
         <Helmet>
            <meta charSet="utf-8" />
            <title>Top PPC Advertising Services| Digital Buzzers</title>
            <meta name="Digital Buzzers is a leading company in Google Ads & PPC Advertising. We grow your business with a team of experts. Get a quote today!"
                content="
                best ppc advertising companies,
                ppc agency near me,
                ppc company near me,
                ppc marketing firm,
                top ppc companies,
                ppc firm usa,
                best ppc management company,
                ppc agency usa,
                ppc management uk,
                ppc agency in london,
                ppc agency in uk
                "/>
        </Helmet>
        <div className="page-banner contact-banner">
            <div className="t2 bold1-txt center mb">Pay Per Click Advertising</div>
            <div className="t5 center bold1-txt">Struggling to attract new clients? Our unique system consistently <span className="yellow-color">attracts, converts and delivers </span>   results.</div>
        </div>
        <Row>
            <Col xs={24} md={24} lg={12}>
                <img src={ppc1} height='100%' width='100%' alt='41'/>
            </Col>
            <Col xs={24} md={24} lg={12} className='data-creativity-content'>
                <div className='t4 bold1-txt mb2'>It’s all about results</div>
                <div className='t6 mb bold4-txt'>At Digital Buzzers, we understand the importance of paid advertising for businesses to drive traffic and increase revenue.</div>
                <div className='t6 mb bold4-txt'>Our team of experts is dedicated to creating custom-tailored paid advertising campaigns that suit the needs of your business and its goals.</div>
                <div className='t6 mb bold4-txt'>Our paid advertising services include Google Ads, Facebook Ads, Instagram Ads, Youtube Ads, Linkedin Ads and more. With our extensive experience and knowledge of the latest trends and strategies, we can create campaigns that target your ideal audience and maximize your return on investment.</div>

                <Row align='middle' gutter={[8, 16]} className='mt2'>
                    <Col xs={24} md={7} lg={12}>
                        <button htmlType="submit" className='submit-btn services-web-design-btn t5 bold1-txt'><a href='#free-consulation' className='darkblue-color services-web-design-btn-txt'>Increase My Revenue</a></button>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row>
            <Col xs={24} md={0} lg={0}>
                <img src={ppc2} height='100%' width='100%' alt='39'/>
            </Col>
            <Col xs={24} md={24} lg={12} className='data-creativity-content'>
                <div className='t4 bold1-txt mb2'>High return on investment</div>
                <div className='t6 mb bold4-txt'>By removing the difficulties associated with online marketing, we enable you to focus on your business, customers and growth.</div>
                <div className='t6 mb bold4-txt'>Working with a limited number of clients each month, we have been able to deliver remarkable ROI and have achieved high client retention.</div>
                <div className='t6 mb bold4-txt'>Due to the success of our campaigns and the quality of the leads we deliver, our work has resulted in exponential growth for our clients.</div>
                <div className='t6 mb bold4-txt'>We currently have only very few client slots available, so please register your interest ASAP!</div>
                <Row align='middle' gutter={[8, 16]} className='mt2'>
                    <Col xs={24} md={9} lg={12}>
                        <button htmlType="submit" className='submit-btn services-web-design-btn t5 bold1-txt'><a href='#free-consulation' className='darkblue-color services-web-design-btn-txt'>Book a Consulation</a></button>
                    </Col>
                    <Col xs={24} md={15} lg={12} >
                    <div className='t6 bold4-txt'> or call us on <a href="tel:9062758799" className="yellow-color" target={'_blank'}> (+91) 982 079 6941</a></div>
                    </Col>
                </Row>
            </Col>
            <Col xs={0} md={24} lg={12}>
                <img src={ppc2} height='100%' width='100%' alt='40'/>
            </Col>
        </Row>
        <AboutUsSlider
            sliderTxt={[
                'I have been extremely impressed by the customer service, attention to detail and all-round performance of this fantastically well run company. I will be highly recommending to anyone and everyone!',
                'Digital Buzzers built us a beautiful ecommerce website with a wealth of custom features and layouts. The backend is super simple to use, and their marketing, advice and SEO offering have meant that we have hit the ground running and are already bringing in sales!',
                'They took us through the design process from concept to delivery for our new school website. We are extremely pleased with Digital Buzzers service.'
            ]} />
        <FreeConsultation />
    </div>)
}
export default PPCAdvetising;