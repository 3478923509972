import './Footer.css'
import { Row, Col } from 'antd';
import { PhoneFilled, MailFilled, BankFilled, LinkedinFilled , FacebookFilled ,InstagramFilled} from '@ant-design/icons'
import logo from '../img/logo.png';

const Footer = () => {
    return (<div className='footer' id='footer'>
        <Row gutter={[32, 32]} className='white-color'>
            <Col xs={24} md={10} lg={8} xxl={8}>
                <div className='t5 bold1-txt mb2'>Get in touch</div>
                <a href="tel:9062758799"><div className='t6 mb2 white-blue-color'> <PhoneFilled className='yellow-color t6 mr' /> (+91) 982 079 6941</div></a>
                <a href=""><div className='t6 mb2 white-blue-color'><MailFilled className='yellow-color t6 mr' />Hello@digitalbuzzers.com</div></a>
                <div className='t6 white-blue-color'><BankFilled className='yellow-color t6 mr' />67B, Gopal Mansion, Bandra West, <br/>Mumbai 400050.</div>
            </Col>
            <Col xs={24} md={8} lg={5} xxl={4}>
                <div className='t5 bold1-txt mb2'>About us</div>
                <a href="/"><div className='t6 mb white-blue-color'>Home</div></a>
                <a href='/our-work'><div className='t6 mb white-blue-color'>Portfolio</div></a>
                <a href='/about-us'><div className='t6 mb white-blue-color'>About</div></a>
                <a href='/contact'><div className='t6 white-blue-color'>Contact</div></a>
            </Col>
            <Col xs={24} md={6} lg={5} xxl={4}>
                <div className='t5 bold1-txt mb2'>Services</div>
                <a href="/services/web-design"><div className='t6 mb white-blue-color'>Web Design</div></a>
                <a href='/services/ppc-advertising'><div className='t6 mb white-blue-color'>PPC Advertising</div></a>
                <a href='/services/seo'><div className='t6 mb white-blue-color'>SEO</div></a>
                <a href="/services/email-sms-marketing"><div className='t6 mb white-blue-color'>Email SMS Marketing</div></a>
                <a href='/services/social-media'><div className='t6 mb white-blue-color'>Social Media</div></a>
                <a href='/services/tech-service'><div className='t6 mb white-blue-color'>Tech Service</div></a>
                <a href='/services/content-creation'><div className='t6 white-blue-color'>Content Creation</div></a>
            </Col>
            <Col xs={24} md={12} lg={6} xxl={8}>
                <img src={logo} width={'80%'} alt='4'/>
                <div className='t6 mb2 mt2 white-blue-color'>Get the latest news and marketing tips by following on:</div>
                <Row gutter={[16,16]}>
                    <Col span={12}>
                        <a  href="https://www.linkedin.com/company/digital-buzzers/"  target={'_blank'}><div className='t6 white-blue-color'> <LinkedinFilled  className='yellow-color t5 mr' /> LinkedIn</div></a>
                    </Col>
                    <Col span={12}>
                        <a href="https://www.facebook.com/profile.php?id=100091566953349&mibextid=LQQJ4d"  target={'_blank'}><div className='t6 white-blue-color'> <FacebookFilled className='yellow-color t5 mr' /> FaceBook</div></a>
                    </Col>
                    <Col span={12}>
                        <a href="https://instagram.com/digitalbuzzers?igshid=MWQ2ODkyMjM="  target={'_blank'}><div className='t6 white-blue-color'> <InstagramFilled  className='yellow-color t5 mr' /> Instagram</div></a>
                    </Col>
                </Row>
            </Col>
        </Row>
        <hr className='mt2 mb2'></hr>
    </div>)
}
export default Footer;