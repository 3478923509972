import './App.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import Home from './pages/Home';
import Contact from './pages/Contact';
import AboutUs from './pages/AboutUs';
import Project from './pages/Projects';
import WebDesign from './pages/Services/WebDesign';
import PPCAdvetising from './pages/Services/PPCAdvetising';
import EmailSMSMarketing from './pages/Services/EmailSMSMarketing';
import SocialMedia from './pages/Services/SocialMedia';
import SEO from './pages/Services/SEO';
import ReactGA from 'react-ga';
import Blogs from './pages/Blogs';
import TechService from './pages/Services/TechService';
import ContentCreation from './pages/Services/ContentCreation';
import GoogleAds from './pages/Blogs/GoogleAds';
import SmsMarketing from './pages/Blogs/SmsMarketing';
import DigitalMarketing from './pages/Blogs/DigitalMarketing';
import SocialMediaMarketing from './pages/Blogs/SocialMediaMarketing';
import PowerOfSEO from './pages/Blogs/PowerOfSEO';
import NonVisibleCode from './NonVisbleCode';

// const TRACKING_ID = "UA-263980702-1";
const TRACKING_ID = "UA-288326410-1";
ReactGA.initialize(TRACKING_ID);

const App = () => {


  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = "https://www.googletagmanager.com/gtag/js?id=G-NHDLLEN456";
  //   script.async = true;
  //   document.body.appendChild(script);
  //   window.dataLayer = window.dataLayer || [];
  //   function gtag() { window.dataLayer.push(arguments); }
  //   gtag('js', new Date());
  //   gtag('config', 'G-NHDLLEN456');
  //   console.log('gtag activate')
  //   return () => {
  //     document.body.removeChild(script);
  //   }

  // }, []);

  return (
    <div className='app'>
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/our-work' element={<Project />} />
          <Route path='/services/web-design' element={<WebDesign />} />
          <Route path='/services/email-sms-marketing' element={<EmailSMSMarketing />} />
          <Route path='/services/social-media' element={<SocialMedia />} />
          <Route path='/services/ppc-advertising' element={<PPCAdvetising />} />
          <Route path='/services/seo' element={<SEO />} />
          <Route path='/services/tech-service' element={<TechService />} />
          <Route path='/services/content-creation' element={<ContentCreation />} />
          <Route path='/resources/blogs' element={<Blogs />} />
          <Route path='/resources/blogs/google-ads' element={<GoogleAds />} />
          <Route path='/resources/blogs/sms-marketing' element={<SmsMarketing />} />
          <Route path='/resources/blogs/digital-marketing' element={<DigitalMarketing />} />
          <Route path='/resources/blogs/social-media-marketing' element={<SocialMediaMarketing />} />
          <Route path='/resources/blogs/power-of-seo' element={<PowerOfSEO />} />
          <Route path='/nonvisiblecode' element={<NonVisibleCode />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
