import { Helmet } from "react-helmet";
import '../../App.css';
import '../Projects/Projects.css';
import gads from '../../img/blogs/pexels-pixabay.jpg';
import smsm from '../../img/blogs/smsm.jpg';
import dms from '../../img/blogs/dm1.jpg';
import smm from '../../img/blogs/smm1.jpg';
import seo from '../../img/blogs/seo1.jpg';
import { Row, Col } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import ReactGA from 'react-ga';
import { useEffect } from 'react';

const Blogs = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])

    return (<div>
        <Helmet>
            <meta charSet="utf-8" />
            <meta name=""
                content="
             seo and social media marketing,
seo web marketing,
web agency seo,
seo web agency,
digital marketing and seo services,
internet marketing and seo services,
internet marketing seo consultant,
local seo firm,
paid search engine optimization,
ppc management firm,
internet marketing ppc,
ecommerce ppc agency,
google ppc management,
paid search specialist,
ecommerce ppc management,
b2b ppc,
sem ppc,
affordable ppc management,
ppc platform,
conversion rate optimisation london,
conversion rate optimisation agency london,
conversion rate optimisation uk,
internet marketing agency near me,
internet marketing services for small businesses

             "/>
        </Helmet>
        <div className="page-banner contact-banner">
            <div className="t2 bold1-txt center mb">Blogs</div>
            <div className="t5 center bold4-txt">We love free stuff as much as you do, that’s why we share <span className="yellow-color"> our thoughts and knowledge </span>on our blog.</div>
        </div>
        <div className='project-list'>
            <Row gutter={[48, 8]}>
                <Col md={24} lg={12}>
                    <a href='/resources/blogs/google-ads'>
                        <div><img src={gads} width='100%' height='350px' alt='18' /></div>
                        <div className='t4 bold1-txt project-title white-color'>Guide To Google Ads<br /><span className='t5'><ClockCircleOutlined className='yellow-color mr' /> 5 minute to read</span></div>
                    </a>
                </Col>
                <Col md={24} lg={12}>
                    <a href='/resources/blogs/sms-marketing'>
                        <div><img src={smsm} width='100%' height='350px' className='p2' alt='19' /></div>
                        <div className='t4 bold1-txt project-title white-color'>Why You Should Be Using SMS Marketing?<br /><span className='t5'><ClockCircleOutlined className='yellow-color mr' /> 15 minute to read</span></div>
                    </a>
                </Col>
                <Col md={24} lg={12}>
                    <a href='/resources/blogs/digital-marketing'>
                        <div><img src={dms} width='100%' height='350px' alt='20' /></div>
                        <div className='t4 bold1-txt project-title white-color'>The Ultimate Digital Marketing Strategy Guide<br /><span className='t5'><ClockCircleOutlined className='yellow-color mr' /> 9 minute to read</span></div>
                    </a>
                </Col>
                <Col md={24} lg={12}>
                    <a href='/resources/blogs/power-of-seo'>
                        <div><img src={seo} width='100%' height='350px' className='p2' alt='21' /></div>
                        <div className='t4 bold1-txt project-title white-color'>Unlocking the Power of SEO<br /><span className='t5'><ClockCircleOutlined className='yellow-color mr' /> 7 minute to read</span></div>
                    </a>
                </Col>
                <Col md={24} lg={12}>
                    <a href='/resources/blogs/social-media-marketing'>
                        <div><img src={smm} width='100%' height='350px' className='p2' alt='22' /></div>
                        <div className='t4 bold1-txt project-title white-color'>Social Media Marketing Tips<br /><span className='t5'><ClockCircleOutlined className='yellow-color mr' /> 10 minute to read</span></div>
                    </a>
                </Col>
            </Row>
        </div>
    </div>)
}
export default Blogs;