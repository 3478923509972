
import { CheckOutlined } from '@ant-design/icons';
import { Helmet } from "react-helmet";
import { Row, Col } from 'antd';
import '../../App.css';
import './Services.css';
import '../../pages/AboutUs/AboutUs.css';
import webdesign from '../../img/Services/WebDesing/Exper-in-web-design.png';
import webdesign1 from '../../img/Services/WebDesing/Wordpress-web-design.png';
import BasicCard from '../../Component/BasicCard/BasicCard';
import FreeConsultation from '../AboutUs/FreeConsultation';
import AboutUsSlider from '../AboutUs/AboutUsSlider';
import customFoucs from '../../img/Services/WebDesing/Customer-focused.png'
import mobilefriendly from '../../img/Services/WebDesing/mobile-friendly.png';
import notemplates from '../../img/Services/WebDesing/No-templates.png';
import gdpr from '../../img/Services/WebDesing/Gdpr-Compliant.png';
import editablecontent from '../../img/Services/WebDesing/Editable-content.png';
import rocket from '../../img/Services/WebDesing/Optimised-for-speed.png';
import safeBox from '../../img/Services/WebDesing/Security-is-a-must.png';
import accessible from '../../img/Services/WebDesing/Accessible.png';
import googlesearchready from '../../img/Services/WebDesing/Google-search-ready.png';
import flexible from '../../img/Services/WebDesing/Flexible-involvement.png';
import fulltraining from '../../img/Services/WebDesing/Full-training.png';
import support from '../../img/Services/WebDesing/Support-and-maintenance.png';
import ReactGA from 'react-ga';
import { useEffect } from 'react';

const WebDesign = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])

    const features = [
        'Unlimited posts, pages and products',
        'Visitor analytics & heatmaps',
        'Built-in SEO analysis',
        'Project roadmap',
        'Newsletter automation',
        'Ongoing advice and guidance',
        'Competitor analysis',
        'Integrations & time-saving features',
        'Friendly in-house team',
        'Keyword research',
        'Brand style guides',
        'Premium plugins',
        'Content analysis',
        'Built-in content readability analysis',
        'We really care about our clients!'
    ]
    return (<div className='darkblue-color'>
        <Helmet> 
            <meta charSet="utf-8" />
            <meta name=""
                content="
                web designing company in uk,
web development companies in usa,
website development company in usa,
web development company in uk,
website design and digital marketing company

                "/>
        </Helmet>
        <div className="page-banner contact-banner">
            <div className="t2 bold1-txt center mb">Web Design & Development</div>
            <div className="t5 center bold1-txt">Custom-made websites that increase revenue and consistently <span className="yellow-color">attract, convert and deliver</span>  paying clients.</div>
        </div>
        <Row>
            <Col xs={24} md={24} lg={12}>
                <img src={webdesign} height='100%' width='100%' alt='47' />
            </Col>
            <Col xs={24} md={24} lg={12} className='data-creativity-content'>
                <div className='t4 bold1-txt mb2'>Web Design Experts</div>
                <div className='t6 mb bold4-txt'>Elevate your business with a beautiful website that your customers will love.Drive users to action and grow your brand with a results-driven experience.</div>
                <div className='t6 mb2 mt2 bold1-txt'>What do we specialise in?</div>
                <div className='t6 mb bold4-txt'> <span className='mr t5 bold1-txt green-color'><CheckOutlined /></span>B2C and B2B website design & development.</div>
                <div className='t6 mb bold4-txt'> <span className='mr t5 bold1-txt green-color'><CheckOutlined /></span>Ecommerce stores, integrations and conversion optimisation.</div>
                <div className='t6 mb bold4-txt'> <span className='mr t5 bold1-txt green-color'><CheckOutlined /></span>Custom website and landing page.</div>
                <div className='t6 mb bold4-txt'> <span className='mr t5 bold1-txt green-color'><CheckOutlined /></span>Website hosting and maintenance.</div>

                <Row align='middle' gutter={[8, 16]} className='mt2'>
                    <Col xs={24} md={7} lg={8}>
                        <button htmlType="submit" className='submit-btn services-web-design-btn t5 bold1-txt'><a href='/contact' className='darkblue-color services-web-design-btn-txt'>Contact Us</a></button>
                    </Col>
                    <Col xs={24} md={17} lg={16} >
                        <div className='t6 bold4-txt'> or call us on <a href="tel:9062758799" className="yellow-color" target={'_blank'}> (+91) 982 079 6941</a></div>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row>
            <Col xs={24} md={0} lg={0}>
                <img src={webdesign1} height='100%' width='100%' alt='48' />
            </Col>
            <Col xs={24} md={24} lg={12} className='data-creativity-content'>
                <div className='t4 bold1-txt mb2'>Website Design Process</div>
                <div className='t6 mb bold4-txt'>Moreover, our team are senior WordPress and Shopify experts and can help you achieve your wildest website dreams.</div>
                <div className='t6 mb bold4-txt'> <span className='mr t5 bold1-txt green-color'><CheckOutlined /></span>Planning your new website.</div>
                <div className='t6 mb bold4-txt'> <span className='mr t5 bold1-txt green-color'><CheckOutlined /></span>High-quality website content.</div>
                <div className='t6 mb bold4-txt'> <span className='mr t5 bold1-txt green-color'><CheckOutlined /></span>Eye-catching creative design.</div>
                <div className='t6 mb bold4-txt'> <span className='mr t5 bold1-txt green-color'><CheckOutlined /></span>Website development.</div>
                <Row align='middle' gutter={[8, 16]} className='mt2'>
                    <Col xs={24} md={9} lg={10}>
                        <button htmlType="submit" className='submit-btn services-web-design-btn t5 bold1-txt'><a href='/contact' className='darkblue-color services-web-design-btn-txt'>Free Consulation</a></button>
                    </Col>
                    <Col xs={24} md={15} lg={14} >
                        <div className='t6 bold4-txt'> or call us on <a href="tel:9062758799" className="yellow-color" target={'_blank'}> (+91) 982 079 6941</a></div>
                    </Col>
                </Row>
            </Col>
            <Col xs={0} md={24} lg={12}>
                <img src={webdesign1} height='100%' width='100%' alt='49' />
            </Col>
        </Row>
        <div className='plus-all-services'>
            <div className='t4 bold1-txt center mb2'>Plus all of these features…</div>
            <Row gutter={[32, 16]} >
                {features.map(item => {
                    return <Col xs={24} md={12} lg={8}><div className='t7 mb bold1-txt'> <span className='mr t6 bold1-txt green-color'><CheckOutlined /></span>{item}</div></Col>
                })}
            </Row>
        </div>
        <div>
            <div className='we-are-different'>
                <div className='t3 bold1-txt center mb'>What’s included?</div>
                <div className='t6 bold1-txt center mb2'>Every web design project is different, so we can’t define your complete feature list or exact pricing. However, these are our core ingredients for website success.</div>
                <br />
                <Row gutter={[32, 32]} >
                    <Col xs={24} md={12} lg={8}>
                        <BasicCard
                            img={mobilefriendly}
                            description='If your website isn’t responsive, then you’re missing out on sales without a doubt! Every website we build works flawlessly on desktop, tablet and mobile, ensuring that all of your visitors have a seamless experience.'
                            title='Mobile friendly'
                        />
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <BasicCard
                            img={customFoucs}
                            description='Rather than simply do “what feels right”, we focus our designs around your customers, making it easy for them to find the content or products that they’re looking for. Eg. product details, specs or your phone number.'
                            title='Customer-focused'
                        />
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <BasicCard
                            img={notemplates}
                            description='We don’t use any pre-made themes or templates. Everything we create is 100% bespoke and original; created by our expert in-house team so that it’s what will work best for your business.'
                            title='No templates'
                        />
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <BasicCard
                            img={gdpr}
                            description='You can rest assured knowing that your website is GDPR (General Data Protection Regulation) compliant. We’ll provide guidelines and a privacy policy outlining how the site handles visitors’ information.'
                            title='GDPR compliant'
                        />
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <BasicCard
                            img={editablecontent}
                            description='Every website we build comes with an easy-to-use dashboard where you can modify content such as text and images, as well as adding, editing and deleting pages, blog posts and products – all in one place.'
                            title='Editable content'
                        />
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <BasicCard
                            img={rocket}
                            description='Every website is hand developed using latest standards that are not only secure but also faster. We develop and optimise our websites from the ground up to ensure that it’s running as quickly and smoothly as possible.'
                            title='Optimised for speed'
                        />
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <BasicCard
                            img={safeBox}
                            description='Whether you take transactions, store client data or simply have a contact form that sends you an email, our developers will secure your site to a high enterprise-grade standard for your peace-of-mind.'
                            title='Security is a must'
                        />
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <BasicCard
                            img={accessible}
                            description='Your new website will be accessible to users with different disabilities in order to conform to The Equality Act 2010. This will include screen reader accessibility, colour contrasts, link titles, form labels, images and much more.'
                            title='Accessible'
                        />
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <BasicCard
                            img={googlesearchready}
                            description='A good code structure and schema information is of paramount importance for SEO. We include and handle everything that Google needs to rank your pages, posts and products in their search results.'
                            title='Google Search ready'
                        />
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <BasicCard
                            img={flexible}
                            description='You’re great at running your business, meanwhile, we’re experts in research, design, user experience, development and all-things-technical. You can either be involved in every step or can sit back and relax!'
                            title='Flexible involvement'
                        />
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <BasicCard
                            img={fulltraining}
                            description='We’ll provide you and your team with training so that you know how to use and update your new website. This can include face-to-face training, video call, pre-recorded tutorials and text documentation.'
                            title='Full training'
                        />
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <BasicCard
                            img={support}
                            description='We offer free support and maintenance for 1 to 2 months after launch to get you off to a good start after your new website goes live. This ensures that we can iron out any creases and guide you in a real-world scenario.'
                            title='Support & maintenance'
                        />
                    </Col>
                </Row>
            </div>
        </div>
        <div className='plus-all-services'>
            <div className='t2 bold1-txt center mb2'>Our process</div>
            <div className='t6 center bold4-txt mb2'>A website design process is the foundation of your new website project and will determine the success of the project post-launch. Here’s how we get incredible results for our clients:</div>
            <br />
            <Row gutter={[64, 16]}>
                <Col md={24} lg={12} >
                    <div className='webdesign-process'>
                        <div className='t4 bold1-txt mb2'><span className='number'>1</span>Discovery workshop</div>
                        <div className='t6 mt'>Arguably the most important step – the initial meeting creates a roadmap for the entire project. During our website workshop, you’ll tell us your requirements, we’ll set goals, come up with a sitemap, and we’ll discuss your business inside out to ensure we understand your customers and processes. Overall, the site or system aims to increase revenue while lowering your costs.</div>
                    </div>
                </Col>
                <Col md={24} lg={12}>
                    <div className='webdesign-process p2'>
                        <div className='t4 bold1-txt mb2'><span className='number'>2</span>Competitor & SEO research</div>
                        <div className='t6 mt'>We’ll look at successful trends, the psychology of getting visitors to click, your current website data and competitor website audits. Using this information, we’ll create an internal strategy of how to structure your website to position your bespoke WordPress website for success. Our research stage saves you costs down the line as your website will be SEO optimised for launch.</div>
                    </div>
                </Col>
                <Col md={24} lg={12} >
                    <div className='webdesign-process'>
                        <div className='t4 bold1-txt mb2'><span className='number'>3</span>Bespoke design</div>
                        <div className='t6 mt'>Every project we work on is bespoke and started from scratch. During the bespoke design stage, we’ll create a style guide and mockups of the new site to give you an idea of how we envisage the website. The designs will utilise best practices and guidelines that will ensure that your website is easy to use and converts as many users into paying customers as possible.</div>
                    </div>
                </Col>
                <Col md={24} lg={12}>
                    <div className='webdesign-process p2'>
                        <div className='t4 bold1-txt mb2'><span className='number'>4</span>Web development</div>
                        <div className='t6 mt'>Developing a WordPress or ecommerce website is a time-consuming but hugely important part of the project. The developer will use the designs to code the site so that it’s fast and secure, incorporating the latest technologies in the industries. The result is a fully functional website with working and tested features so that the website can go live and start promoting you online.</div>
                    </div>
                </Col>
                <Col md={24} lg={12} >
                    <div className='webdesign-process'>
                        <div className='t4 bold1-txt mb2'><span className='number'>5</span>Training</div>
                        <div className='t6 mt'>WordPress is easy-to-use and there are plenty of tutorials on the internet, but we’ll train you and your team one-on-one to ensure that you’re a WordPress guru! Our training is structured to ensure that you walk away confident about editing and adding new content to your new website. Don’t fear, if you have any questions down the line, we’re on the other end of the phone.</div>
                    </div>
                </Col>
                <Col md={24} lg={12}>
                    <div className='webdesign-process p2'>
                        <div className='t4 bold1-txt mb2'><span className='number'>6</span>Launch</div>
                        <div className='t6 mt'>An exciting phase: launch! The developed website will be rigorously tested and then set ‘live’ for the world to see and use. Our team will optimise the site further and will take care of submitting your new website to Google, as well as ensuring that there’s a smooth transition between your old site and the newly launched website. To ensure that your site is running smoothly, we’ll test again and again!</div>
                    </div>
                </Col>
                <Col md={24} lg={12} >
                    <div className='webdesign-process'>
                        <div className='t4 bold1-txt mb2'><span className='number'>7</span>Bug fixing</div>
                        <div className='t6 mt'>All of our websites are launched with 2 months of free bug fixing. This ensures that you’re off to a good start and gives you an insight into our paid support and maintenance packages. We’ll take daily backups of your website, ensure that your WordPress platform and plugins are the latest version and will be on the other end of the phone if you need any help.</div>
                    </div>
                </Col>
                <Col md={24} lg={12}>
                    <div className='webdesign-process p2'>
                        <div className='t4 bold1-txt mb2'><span className='number'>8</span>Ongoing improvements</div>
                        <div className='t6 mt'>Just because the website has launched it doesn’t mean that you should leave the site untouched for a few years! Our tools will show how users interact with your new site. With your users’ best interests at heart, we’ll analyse your website’s data and will use it to make modifications to ensure that your WordPress website is performing at its best – from a technical, visual and informational and point of view.</div>
                    </div>
                </Col>
            </Row>
        </div>
        <AboutUsSlider
            sliderTxt={[
                'I have been extremely impressed by the customer service, attention to detail and all-round performance of this fantastically well run company. I will be highly recommending to anyone and everyone!',
                'Digital Buzzers built us a beautiful ecommerce website with a wealth of custom features and layouts. The backend is super simple to use, and their marketing, advice and SEO offering have meant that we have hit the ground running and are already bringing in sales!',
                'They took us through the design process from concept to delivery for our new school website. We are extremely pleased with Digital Buzzers impeccable service.'
            ]} />
        <FreeConsultation />
    </div>)
}
export default WebDesign;