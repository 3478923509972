import './Section.css';
import '../../App.css';
import { Col, Row } from 'antd';

const Section = ({ title, content, btntxt,btnlink, subtitle, linktxt, link, image, leftImg ,link2,link2txt,link3txt,descriptionSpan=24}) => {
    return (<>
        <div className='thinking-beyond-web-design'>
            <Row>
                {leftImg &&
                    <Col md={24} lg={12}>
                        <img src={image} width='100%' height='100%' alt='2'/>
                    </Col>
                }
                <Col md={24} lg={12} className='thinking-beyond-web-design-right-part'>
                    <div className='t3 white-color bold1-txt mb2'>{title}</div>
                    <Row>
                        <Col xs={24} lg={descriptionSpan}>
                    {content.map(item => {
                        return (
                            <div className='t6 white-blue-color mb2 section-description'>{item}</div>
                        )
                    })}
                    </Col>
                    </Row>
                    <Row align='middle' gutter={[8, 16]} className='mt2'>
                        <Col xs={24} md={7} lg={9}>
                            <button htmlType="submit" className='submit-btn web-design-btn t5 bold1-txt'><a href={btnlink} className='darkblue-color web-design-btn-txt'>{btntxt}</a></button>
                        </Col>
                        <Col xs={24} md={17} lg={15} >
                            <div className='t5 white-color'> {subtitle} { linktxt && <a href={link} className="yellow-color" target={'_blank'}> {linktxt}</a>} { link2txt && <a href={link2} className="yellow-color" target={'_blank'}> {link2txt}</a>}{ link3txt && <a href="#" className="yellow-color ml" target={'_blank'}> {link3txt}</a>}</div>
                        </Col>
                    </Row>
                </Col>
                {!leftImg &&
                    <Col md={24} lg={12}>
                        <img src={image} width='100%' height='100%' alt='3'/>
                    </Col>
                }
            </Row>
        </div>
    </>)
}

export default Section;