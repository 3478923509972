
import { Row, Col } from 'antd'
import ContactForm from '../Contact/ContactForm';

const FreeConsultation = () => {
    return (
        <div id='free-consulation'>
            <Row className='about-us-contact' align='middle'>
                <Col xs={24} lg={10} className='plr2'>
                    <div className='t3 bold1-txt mb2'>Free consultation</div>
                    <div className='t6 mb2'>Call us on <b>(+91) 982 079 6941 </b>or fill out the form and a Digital marketing expert will call you back.</div>
                </Col>
                <Col id='about-us-contact-form' xs={24} lg={14}>
                    <ContactForm />
                </Col>
            </Row>
        </div>)
}
export default FreeConsultation;