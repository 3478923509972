import { Row, Col } from 'antd';
import './AboutUs.css';
import '../../App.css';
import FreeConsultation from './FreeConsultation';
import AboutUsSlider from './AboutUsSlider';
import BasicCard from '../../Component/BasicCard/BasicCard';
import dataCreativity from '../../img/AboutUs/Data+Creativity.png';
import whiteboardplanning from '../../img/AboutUs/We-make-success-a-priority.png';
import edenreforestationprojects from '../../img/AboutUs/Climate-positive-workforce.png';
import workings from '../../img/AboutUs/Working-at-Digital-Buzzers.png';
import weListen from '../../img/AboutUs/We-listen.png';
import weAreGood from '../../img/AboutUs/We-are-experts.png';
import weheartostay from '../../img/AboutUs/We-are-here-to-stay.png';
import weliketalking from '../../img/AboutUs/We-are-here-to-stay.png';
import wereinexpensive from '../../img/AboutUs/We-are-inexpensive.png';
import werehonest from '../../img/AboutUs/We-are-honest.png';
import ReactGA from 'react-ga';
import { useEffect } from 'react';

const AboutUs = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])

    return (<div className='darkblue-color'>
        <div className="page-banner contact-banner">
            <div className="t1 bold1-txt center mb2">About</div>
            <div className="t5 center bold4-txt">Digital Buzzers is an experienced full-service <span className="yellow-color">digital marketing agency</span>  working with local and international businesses.</div>
        </div>
        <Row>
            <Col xs={24} md={24} lg={12}>
                <img src={dataCreativity} height='100%' width='100%' alt='6'/>
            </Col>
            <Col xs={24} md={24} lg={12} className='data-creativity-content'>
                <div className='t3 bold1-txt mb2'>Data + creativity = RESULTS</div>
                <div className='t6 mb2 bold4-txt'>We combine extensive research, experience and creativity to provide bespoke solutions for clients locally and throughout the world.</div>
                <div className='t6 mb2 bold4-txt'>When you meet us, there will be no hard selling — only full transparency from experts you can trust.</div>
                <div className='t6 mb2 bold4-txt'>You will quickly see that we take the time to learn and listen, allowing us to create solutions that leverage your current assets and understand users fully to create real revenue-focused results.</div>
            </Col>
        </Row>
        <Row> 
            <Col xs={24} md={0} lg={0}>
                <img src={whiteboardplanning} height='100%' width='100%' alt='7'/>
            </Col>
            <Col xs={24} md={24} lg={12} className='data-creativity-content'>
                <div className='t3 bold1-txt mb2'>We make your success a priority</div>
                <div className='t6 mb2 bold4-txt'>Digital Buzzers is an independent marketing and digital agency that goes beyond typical challenges and aims to exceed client expectations.</div>
                <div className='t6 mb2 bold4-txt'>We quickly acquired a reputation for our unparalleled technical know-how, the highest quality of work and unmatched dedication to our clients’ successes.</div>
                <div className='t6 mb2 bold4-txt'>Our mission is simple: to bring design and technology together to create lasting, tangible financial results for our clients.</div>
            </Col>
            <Col xs={0} md={24} lg={12}>
                <img src={whiteboardplanning} height='100%' width='100%' alt='8'/>
            </Col>
        </Row>
        <Row>
            <Col xs={24} md={24} lg={12}>
                <img src={edenreforestationprojects} height='100%' width='100%' alt='9'/>
            </Col>
            <Col xs={24} md={24} lg={12} className='data-creativity-content'>
                <div className='t3 bold1-txt mb2'>Climate positive workforce</div>
                <div className='t6 mb2 bold4-txt'>Working in a digital era, we go above and beyond for our clients and end-users. However, it’s equally important to look after the planet for future generations.</div>
                <div className='t6 mb2 bold4-txt'>Aside from choosing an environmentally conscious office provider that recycled the office building we are in, we have also partnered with Ecologi and Eden Reforestation Projects to plant trees and offset the carbon emissions of all of our employees – including their private lives, work and travel.</div>
            </Col>
        </Row>
        <Row className='box-shadow'> 
            <Col xs={24} md={0} lg={0}>
                <img src={workings} height='100%' width='100%' alt='10'/>
            </Col>
            <Col xs={24} md={24} lg={12} className='data-creativity-content'>
                <div className='t3 bold1-txt mb2'>Working at Digital Buzzers</div>
                <div className='t6 mb2 bold4-txt'>Digital Buzzers is a modern company, investing in employee wellbeing and happiness at every stage. A relaxed, team-focused approach is at the heart of what we do.</div>
                <div className='t6 mb2 bold4-txt'>We are a fully remote-working company. Although we have an office, Digital Buzzers employees and contractors can work from any location they pick.</div>
                <div className='t6 mb2 bold4-txt'>Interested in working with us? Email hello@gmail.com</div>
            </Col>
            <Col xs={0} md={24} lg={12}>
                <img src={workings} height='100%' width='100%' alt='11'/>
            </Col>
        </Row>
        <div className='we-are-different'>
            <div className='t3 bold1-txt center mb2'>What makes us different?</div>
            <br />
            <Row gutter={[32, 32]} >
                <Col xs={24} md={12} lg={8}>
                    <BasicCard
                        img={weListen}
                        description='The first thing we do is listen, learn about you and plan. Thoroughly learning about your business, clients and processes will help us understand how to best present your brand and deliver results.'
                        title='We listen'
                    />
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <BasicCard
                        img={weAreGood}
                        description={`Creating websites isn’t our job – it’s our hobby. We’re experienced with achieving revenue-changing results and continually adopt new technologies. That's why even our competitors use us!`}
                        title='We’re experts'
                    />
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <BasicCard
                        img={weheartostay}
                        description='Unlike other agencies, we don’t work as a factory production line. Instead, we stay in touch, offer free advice and continually help clients improve their digital presence long after launch.'
                        title='We’re here to stay'
                    />
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <BasicCard
                        img={weliketalking}
                        description='You’ll know everyone working on the project and we’ll keep you up-to-date about our work. You’ll know exactly what’s going on, what will come next and what we need to do to get there.'
                        title='We like talking'
                    />
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <BasicCard
                        img={werehonest}
                        description={`We don’t want to overcomplicate life for you or ourselves. So we’re honest and transparent when it comes to everything. You won’t find any surprise fees or covered up problems here!`}
                        title={`We're honest`}
                    />
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <BasicCard
                        img={wereinexpensive}
                        description='We believe that professional doesn’t have to mean expensive. Our priority is to get you more customers and increase your profit, without charging an arm and a leg for it!'
                        title={`We're inexpensive`}
                    />
                </Col>
            </Row>
        </div>
        <AboutUsSlider
            sliderTxt={[
                'I have been extremely impressed by the customer service, attention to detail and all-round performance of this fantastically well run company. I will be highly recommending to anyone and everyone!',
                'Digital Buzzers built us a beautiful ecommerce website with a wealth of custom features and layouts. The backend is super simple to use, and their marketing, advice and SEO offering have meant that we have hit the ground running and are already bringing in sales!',
                'They took us through the design process from concept to delivery for our new school website. We are extremely pleased with Digital Buzzers impeccable service.'
            ]} />
        <FreeConsultation />
    </div>)
}
export default AboutUs;