import './Header.css'
import { MenuOutlined } from '@ant-design/icons';
import hlogo from '../img/logo.png';
import '../App.css';

const Header = () => {

    window.addEventListener('scroll', function () {
        if (10 < window.scrollY) {
            document.getElementById('nav-bar').classList.add('nav-bar-blue-color');
            document.getElementById('nav-bar').classList.remove('nav-bar-transparent-color');
        }
        else {
            document.getElementById('nav-bar').classList.remove('nav-bar-blue-color');
            document.getElementById('nav-bar').classList.add('nav-bar-transparent-color');
        }
    })
    const changeNavBarColor = (e) => {
        if (e.target.checked === true) {
            document.getElementById('nav-bar').classList.add('nav-bar-blue-color');
            document.getElementById('nav-bar').classList.remove('nav-bar-transparent-color');
        }
        else {
            document.getElementById('nav-bar').classList.remove('nav-bar-blue-color');
            document.getElementById('nav-bar').classList.add('nav-bar-transparent-color');
        }
    }

    const showDropdown = (val) => {
        if (val === 1)
            document.getElementById('dropdown').style.display = 'block';
        else
            document.getElementById('dropdown2').style.display = 'block';
    }
    const hideDropdown = (val) => {
        if (val === 1)
            document.getElementById('dropdown').style.display = 'none';
        else
            document.getElementById('dropdown2').style.display = 'none';
    }

    return (<>
        <div className='nav-bar nav-bar-transparent-color' id='nav-bar'>
            <div></div>
            <div style={{ width: '80%', padding: '5% 0' }} className="header_icon"><a href="/"><img src={hlogo} width="100%" alt='5' /></a></div>
            <div style={{ marginBottom: '10px' }}>
                <input type="checkbox" id="menu-bar" onClick={changeNavBarColor}></input>
                <label htmlFor="menu-bar"> <MenuOutlined className='white-color' /></label>
                <nav>
                    <ul>
                        <li key='home'>
                            <a href="/" className='buzz-out-on-hover'>Home</a>
                        </li>
                        <li key='services'>
                            <a onMouseEnter={() => showDropdown(1)} onMouseLeave={() => hideDropdown(1)}>Services </a>
                            <ul id='dropdown' onMouseEnter={() => showDropdown(1)} onMouseLeave={() => hideDropdown(1)}>
                                <li key='s1'>
                                    <a href="/services/web-design" className='buzz-out-on-hover'>Web Design</a>
                                </li>
                                <li key='s2'>
                                    <a href="/services/ppc-advertising" className='buzz-out-on-hover'>PPC Advertising</a>
                                </li>
                                <li key='s3'>
                                    <a href="/services/seo" className='buzz-out-on-hover'>SEO</a>
                                </li>
                                <li key='s4'>
                                    <a href="/services/social-media" className='buzz-out-on-hover'>Social Media</a>
                                </li>
                                <li key='s5'>
                                    <a href="/services/content-creation" className='buzz-out-on-hover'>Content Creation</a>
                                </li>
                                <li key='s6'>
                                    <a href="/services/tech-service" className='buzz-out-on-hover'>Tech Service</a>
                                </li>
                                <li key='s7'>
                                    <a href="/services/email-sms-marketing" className='buzz-out-on-hover'>Email & SMS Marketing</a>
                                </li>
                            </ul>
                        </li>
                        <li key='our_work'>
                            <a href="/our-work" className='buzz-out-on-hover'>Portfolio</a>
                        </li>
                        {/* <li key='resources'>
                            <a onMouseEnter={() => showDropdown(2)} onMouseLeave={() => hideDropdown(2)}>Resources </a>
                            <ul id='dropdown2' onMouseEnter={() => showDropdown(2)} onMouseLeave={() => hideDropdown(2)}>
                                <li key='r1'>
                                    <a href="/resources/blogs">Blogs</a>
                                </li>
                            </ul>
                        </li> */}
                        <li key='resources'>
                            <a href="/resources/blogs" className='buzz-out-on-hover'>Blogs</a>
                        </li>
                        <li key='about_us'>
                            <a href="/about-us" className='buzz-out-on-hover'>About Us</a>
                        </li>
                        <li key='contact'>
                            <a href="/contact" className='buzz-out-on-hover'>Contact</a>
                        </li>
                        {/* <li key='tel'>
                            <a href="tel:9062758799" style={{ color: '#ffc720' }}>(+91) 982 079 6941</a>
                        </li> */}

                    </ul>
                </nav>
            </div>
        </div>
    </>)
}
export default Header;